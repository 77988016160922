import { Component } from '@angular/core';
import { SessionService } from './session.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  sideMenuActive: boolean;

  constructor(sessionService: SessionService) {
    sessionService.getAccessToken();
  }

  setSideMenuActive(e) {
    this.sideMenuActive = e;
  }
}
