import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  constructor(public messageService: MessageService, private router: Router) { }

  ngOnInit() {
  }

    onConfirm() {
        this.messageService.clear('c');
    }

    onConfirmPub() {
        this.messageService.clear('c');
        this.router.navigate(['/report']);
    }
}
