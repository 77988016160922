import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AdInfo } from '../ad-info';
import { PublicationSettingsService } from '../publication-settings.service';

@Component({
    selector: 'app-preview-bg-item',
    templateUrl: './preview-bg-item.component.html',
    styleUrls: ['./preview-bg-item.component.scss', '../publication-settings.component.scss'],
})
export class PreviewBgItemComponent implements OnInit {
    // publication-settingsのコンポーネントで定義した変数
    @Input() adInfo: AdInfo;

    // 画像データ保存用
    picture = '';

    // 画像データとURLの取得
    get canvas300() {
        return `${this.publicationSettingsService.canvas300}`;
    }
    get canvasOrigin() {
        return `${this.publicationSettingsService.canvasOrigin}`;
    }
    get upload300() {
        return `${this.publicationSettingsService.upload300}`;
    }
    get uploadOrigin() {
        return `${this.publicationSettingsService.uploadOrigin}`;
    }
    get appealUrl() {
        return `${this.publicationSettingsService.appealUrl}`;
    }
    get targetUrl() {
        return `${this.publicationSettingsService.targetUrl}`;
    }

    // ポップアップ表示タイミング用
    get previewHidden() {
        return this.publicationSettingsService.previewHidden;
    }

    // 出稿先のURL保存用(iframe一時凍結のため未使用)
    trustUrl: SafeResourceUrl;

    // 背景画像用
    backPictures: any[] = [];
    backPicture: any;
    picturePreview300: string;
    picturePreviewOrigin: string;

    constructor(private sanitizer: DomSanitizer, private publicationSettingsService: PublicationSettingsService) {}

    ngOnInit() {
        // ギャラリー用配列作成
        this.backPictures.push({source: './../../../assets/images/preview-back/light.jpg'});
        this.backPictures.push({source: './../../../assets/images/preview-back/dark.jpg'});
        // URLを信用
        this.trustUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.targetUrl);
        // 背景の初期値
        this.backPicture = this.backPictures[0].source;
        // プレビューするバナーの切り替え
        if ( this.publicationSettingsService.pictureCreateHide === true ) {
            this.picturePreview300 = this.upload300;
            this.picturePreviewOrigin = this.uploadOrigin;
        } else {
            this.picturePreview300 = this.canvas300;
            this.picturePreviewOrigin = this.canvasOrigin;
        }
    }

    /** 背景画像の選択反映 */
    backPicturesChange(event: any) {
        this.backPicture = this.backPictures[event].source;
    }
}
