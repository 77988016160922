import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BasicSettingsComponent } from './basic-settings/basic-settings.component';
import { ReportComponent } from './report/report.component';
import { AppRoutingModule } from './app-routing.module';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { SlideMenuModule } from 'primeng/slidemenu';
import { CalendarModule } from 'primeng/calendar';
import { GalleriaModule } from 'primeng/galleria';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { MessageService , ConfirmationService} from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PublicationSettingsComponent } from './publication-settings/publication-settings.component';
import { SimpleSettingsComponent } from './publication-settings/simple-settings/simple-settings.component';
import { AdvancedSettingsComponent } from './publication-settings/advanced-settings/advanced-settings.component';
import { PreviewBgFrameComponent } from './publication-settings/preview-bg-frame/preview-bg-frame.component';
import { PreviewBgItemComponent } from './publication-settings/preview-bg-item/preview-bg-item.component';
import { PreviewBgFrameService } from './publication-settings/preview-bg-frame/preview-bg-frame.service';
import { ColorPickerModule } from 'primeng/colorpicker';
import { MessagesComponent } from './messages/messages.component';
import { StorageServiceModule } from 'angular-webstorage-service';
import { CanvasSettingsComponent } from './publication-settings/simple-settings/canvas-settings/canvas-settings.component';
import { ErrorComponent } from './error/error.component';
import { ClipboardModule } from 'ngx-clipboard';
import { CookieService } from 'ngx-cookie-service';
import { httpInterceptorProviders } from './interceptors/http.interceptor';
import { FileNotFoundComponent } from './file-not-found/file-not-found.component';
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';
import { ReportLandscapeComponent } from './report-landscape/report-landscape.component';
@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SidebarComponent,
        DashboardComponent,
        BasicSettingsComponent,
        ReportComponent,
        ReportLandscapeComponent,
        PublicationSettingsComponent,
        SimpleSettingsComponent,
        AdvancedSettingsComponent,
        PreviewBgFrameComponent,
        PreviewBgItemComponent,
        MessagesComponent,
        CanvasSettingsComponent,
        ErrorComponent,
        FileNotFoundComponent,
        SessionTimeoutComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ButtonModule,
        InputTextModule,
        FormsModule,
        ReactiveFormsModule,
        RadioButtonModule,
        FileUploadModule,
        HttpClientModule,
        SlideMenuModule,
        CalendarModule,
        GalleriaModule,
        AccordionModule,
        ColorPickerModule,
        OverlayPanelModule,
        DropdownModule,
        CheckboxModule,
        InputTextareaModule,
        MenuModule,
        MessagesModule,
        MessageModule,
        ToastModule,
        StorageServiceModule,
        ClipboardModule,
        ConfirmDialogModule,
        DragDropModule,
    ],
    providers: [
        PreviewBgFrameService,
        MessageService,
        DatePipe,
        CookieService,
        httpInterceptorProviders,
        ConfirmationService,
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        PreviewBgItemComponent,
    ]
})
export class AppModule { }
