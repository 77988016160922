import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ValueShereService } from '../value-shere.service';
import { SessionService } from '../session.service';
import { ngModuleJitUrl } from '@angular/compiler';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
    @Output() sideMenuActiveChange = new EventEmitter<boolean>();
    items = [{
            label: 'ログアウト',
            url: '/logout',
            command: (event: Event) => {  this.sessionService.clearToStorage('accessToken'); }
        },
    ];
    menuActive = false;
    shopName: string;
    shopUrl: string;
    constructor(
        private valueShareService: ValueShereService,
        private sessionService: SessionService,
        ) { }
    ngOnInit() {
        this.shopUrl = this.sessionService.getShopSiteUrl();
        if (this.shopUrl) {
            this.shopUrl = 'http://' + this.shopUrl;
        } else {
            this.shopUrl = undefined;
        }
        this.shopName = this.sessionService.getShopName();
    }

    onMenuButtonClick(event: Event) {
        this.menuActive = !this.menuActive;
        this.sideMenuActiveChange.emit(this.menuActive);
        event.preventDefault();
    }

    get isError() {
        return this.valueShareService.isError;
    }
}
