import { Const } from './const';
import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, WebStorageService, SESSION_STORAGE} from 'angular-webstorage-service';
import { CookieService } from 'ngx-cookie-service';

const STORAGE_KEY = 'shopId';
const TOKEN_KEY = 'accessToken';
const SHOP_NAME = 'shopName';
const SHOP_URL = 'shopUrl';

@Injectable({
    providedIn: 'root'
})

export class SessionService {

    url: string;
    scriptTag: string;
    cookie: string[];

    constructor(@Inject(SESSION_STORAGE) private storage: WebStorageService, private cookieService: CookieService) {
        // cookieをkeyとvalueに分割
        // this.cookie = document.cookie.split('=');
        // this.saveInLocal(STORAGE_KEY, this.cookie[1]);
    }

    getAccessToken(): void {
        const token = this.cookieService.get(TOKEN_KEY);
        const shopName = this.cookieService.get(SHOP_NAME);
        const shopUrl = this.cookieService.get(SHOP_URL);

        if (token !== null && token !== '') {
            this.saveToStorage(TOKEN_KEY, token);
        }
        if (shopName !== null && shopName !== '') {
            this.saveToStorage(SHOP_NAME, shopName);
        }
        if (shopUrl !== null && shopUrl !== '') {
            this.saveToStorage(SHOP_URL, shopUrl);
        }
        this.cookieService.delete(TOKEN_KEY);
        this.cookieService.delete(SHOP_NAME);
        this.cookieService.delete(SHOP_URL);
    }

    getShopName(): string {
        return this.storage.get(SHOP_NAME);
    }

    getShopSiteUrl(): string {
        return this.storage.get(SHOP_URL);
    }

    /**
     * ローカルストレージにshopIdを保存する
     * @param string key
     * @param string value
     */
    saveToStorage(key, value): void {
        this.storage.set(key, value);
    }
    clearToStorage(key): void {
        this.storage.remove(key);
    }
    /**
     * ダッシュボード画面の情報を取得するためのURLを返却する
     * @param number 選択した月
     * @returns string ダッシュボード情報取得APIのURL
     */
    getDashboardUrl(month: number): string {
        // this.url = Const.dashboardUrl.replace('{shopId}', this.storage.get(STORAGE_KEY)) + '/' + month;
        this.url = Const.dashboardUrl.replace('{shopId}', '1') + '/' + month;
        return this.url;
    }

    /**
     * 基本設定画面の情報を取得するためのURLを返却する
     * @returns string ショップ情報取得APIのURL
     */
    getShopUrl(): string {
        // this.url = Const.shopUrl.replace('{shopId}', this.storage.get(STORAGE_KEY));
        this.url = Const.shopUrl.replace('{shopId}', '1');
        return this.url;
    }

    /**
     * 出稿設定画面の情報を取得するためのURLを返却する。
     * @param string 広告ID
     * @returns string 出稿設定情報取得APIのURL
     */
    getAdInfoUrl(adId: string): string {
        // this.url = Const.adInfoUrl.replace('{shopId}', this.storage.get(STORAGE_KEY)) + '/' + adId;
        this.url = Const.adInfoUrl.replace('{shopId}', '1') + '/' + adId;
        return this.url;
    }

    /**
     * 出稿設定画面の情報を作成するためのURLを返却する。
     * @returns string 出稿設定情報取得APIのURL
     */
    getCreateAdInfoUrl(): string {
        // this.url = Const.adInfoUrl.replace('{shopId}', this.storage.get(STORAGE_KEY));
        this.url = Const.adInfoUrl.replace('{shopId}', '1');
        return this.url;
    }

    /**
     * 出稿設定画面の情報を更新するためのURLを返却する。
     * @param string 広告ID
     * @returns string 出稿設定情報取得APIのURL
     */
    getUpdateAdInfoUrl(adId: string): string {
        // this.url = Const.adInfoUrl.replace('{shopId}', this.storage.get(STORAGE_KEY)) + '/' + adId;
        this.url = Const.adInfoUrl.replace('{shopId}', '1') + '/' + adId;
        return this.url;
    }
    /**
     * 出稿情報を取得するためのURLを返却する。
     * @returns string 出稿情報取得APIのURL
     */
    getReportUrl(): string {
        this.url = Const.reportUrl.replace('{shopId}', '1');
        return this.url;
    }
    /**
     * 出稿情報を更新するためのURLを返却する。
     * @returns string 出稿情報変更APIのURL
     */
    getReportStateUrl(adId: string): string {
        this.url = Const.reportStopUrl.replace('{shopId}', '1');
        this.url = this.url.replace('{adId}', adId);
        return this.url;
    }

    /**
     * 出稿情報をコピーするためのURLを返却する。
     * @returns string 出稿情報コピーAPIのURL
     */
    getReportCopyUrl(adId: string): string {
        this.url = Const.reportCopyUrl.replace('{shopId}', '1');
        this.url = this.url.replace('{adId}', adId);
        return this.url;
    }

    getReportDeleteUrl(adId: string): string {
        const url = Const.reportDeleteUrl.replace('{adId}', adId);
        return url;
    }
    /* getScriptTag() {
        this.scriptTag = Const.scriptStr.replace('{shopId}', '1');
        this.scriptTag = this.scriptTag.replace('{serverDomain}',  '"https://popup.elsa.contact.co.jp"');
        return this.scriptTag;
    } */
}
