import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DashboardData } from './dashboard.data';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

    constructor(private http: HttpClient) { }

    siteUrl = 'assets/json/dashboard_get_res.json';

    getSiteInfo(url): Observable<DashboardData> {
        return this.http.get<DashboardData>(url);
    }
}
