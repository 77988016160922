import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PublicationSettingsComponent } from './publication-settings/publication-settings.component';
import { PreviewBgFrameComponent } from './publication-settings/preview-bg-frame/preview-bg-frame.component';
import { BasicSettingsComponent } from './basic-settings/basic-settings.component';
import { ReportComponent } from './report/report.component';
import { ErrorComponent } from './error/error.component';
import { SessionTimeoutComponent} from './session-timeout/session-timeout.component';
import { AuthGuard } from './guard/auth.guard';
import { FileNotFoundComponent } from './file-not-found/file-not-found.component';
import { ReportLandscapeComponent } from './report-landscape/report-landscape.component';

const routes: Routes = [
    { path: 'publication-settings/:adId', component: PublicationSettingsComponent, canActivate: [AuthGuard] },
    { path: 'preview-bg-frame', component: PreviewBgFrameComponent, canActivate: [AuthGuard]  },
    { path: '', component: DashboardComponent, canActivate: [AuthGuard]  },
    { path: 'basic-settings', component: BasicSettingsComponent, canActivate: [AuthGuard]  },
    { path: 'report', component: ReportComponent, canActivate: [AuthGuard]  },
    { path: 'report-landscape', component: ReportLandscapeComponent, canActivate: [AuthGuard]  },
    { path: 'logout', component: ErrorComponent },
    { path: 'session-timeout', component: SessionTimeoutComponent },
    { path: '**', component: FileNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
