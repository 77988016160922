import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Report } from './report';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseData } from '../response-data';
import { SessionService } from '../session.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    constructor(private http: HttpClient, private sessionService: SessionService, private router: Router) { }

    // レポート情報の取得
    getReportInfo(startDate: string, endDate: string): Observable<Report[]> {
        return this.http.get<Report[]>(this.sessionService.getReportUrl() + '?startDate=' + startDate + '&endDate=' + endDate);
    }

    /**
     * 出稿情報を更新する
     * @returns Observable<ResponseData> レスポンスデータ
     */
    updateReportState(adState: object, adId: string): Observable<ResponseData> {
        return this.http.put<ResponseData>(this.sessionService.getReportStateUrl(adId), adState, httpOptions).pipe(
            catchError(this.handleError<ResponseData>('updateReportState'))
        );
    }

    /**
     * 出稿情報をコピーする
     * @returns Observable<ResponseData> レスポンスデータ
     */
    /* copyReport(report: Report, adId: string): Observable<Report[]> {
        return this.http.post<Report[]>(this.sessionService.getReportCopyUrl(adId), report, httpOptions).pipe(
            catchError(this.handleError<Report[]>('copyReport'))
        );
    } */
    copyReport(report: Report, adId: string): Observable<ResponseData> {
        return this.http.post<ResponseData>(this.sessionService.getReportCopyUrl(adId), null, httpOptions).pipe(
            catchError(this.handleError<ResponseData>('copyReport'))
        );
    }

    deleteReport(adId: string): Observable<ResponseData> {
        return this.http.put<ResponseData>(this.sessionService.getReportDeleteUrl(adId), null, httpOptions).pipe(
            catchError(this.handleError<ResponseData>('deleteReport'))
        );
    }

    /** エラー検知 */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }

    getReportType() {
        return this.http.get<string>('/api/front/report-type');
    }

    changeReportType(reportType: string) {
        this.http.put<ResponseData>('/api/front/report-type/' + reportType, null , httpOptions).pipe(
            catchError(this.handleError<ResponseData>('changeReportType'))
        ).subscribe(res => {});

        if (reportType === '1') {
            this.router.navigate(['/report']);
        } else if (reportType === '2') {
            this.router.navigate(['/report-landscape']);
        }
    }

    reportReorder(reports: Report[]) {
        return this.http.put<ResponseData>('/api/front/report-order', reports, httpOptions).pipe(
            catchError(this.handleError<ResponseData>('reportReorder'))
        );
    }
}
