import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { PreviewBgItemComponent } from './preview-bg-item/preview-bg-item.component';
import { PreviewBgFrameService } from './preview-bg-frame/preview-bg-frame.service';
import { PublicationSettingsService } from './publication-settings.service';
import { AdInfo } from './ad-info';
import { PicTemp } from './pic-temp';
import { ResponseData } from '../response-data';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-publication-settings',
    templateUrl: './publication-settings.component.html',
    styleUrls: ['./publication-settings.component.scss'],
})
export class PublicationSettingsComponent implements OnInit, OnDestroy {
    // 詳細設計の表示切り替え用
    hidden = false;

    // 出稿情報の取得用
    adInfo = new AdInfo();
    adId: string;

    // IPアドレス正規表現(URL)
    urlRegExp = '^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$';

    // FormGroup宣言
    simpleSettingsForm = this.fb.group({
        adTitle: this.fb.control(['', Validators.required]),
        popupTargetSegmentOption: this.fb.control(['']),
        appealPageOption: this.fb.control(['']),
        appealPageUrl: this.fb.control(['', Validators.required, Validators.pattern(this.urlRegExp)]),
        popupTargetPageOption: this.fb.control(['']),
        popupTargetPageUrlArray: this.fb.array(['']),
        adBudgetOption: this.fb.control(['']),
        adBudget: this.fb.control(['']),
        pictureCreateOption: this.fb.control(['']),
    });
    advancedSettingsForm = this.fb.group({
        popupDeviceOption: this.fb.control(['']),
        popupTiming: this.fb.control(['', Validators.required]),
        popupPeriodOption: this.fb.control(['']),
        popupIntervalOption: this.fb.control(['']),
        popupInterval: this.fb.control(['']),
        popupIntervalDays: this.fb.control(['']),
        popupIntervalHours: this.fb.control(['']),
        popupIntervalMinutes: this.fb.control(['']),
        publicationStartDate: this.fb.control(['']),
        publicationStopDate: this.fb.control([''])
    });
    get adTitle() {
        return this.simpleSettingsForm.get('adTitle');
    }
    get popupTargetSegmentOption() {
        return this.simpleSettingsForm.get('popupTargetSegmentOption');
    }
    get appealPageOption() {
        return this.simpleSettingsForm.get('appealPageOption');
    }
    get appealPageUrl() {
        return this.simpleSettingsForm.get('appealPageUrl');
    }
    get popupTargetPageOption() {
        return this.simpleSettingsForm.get('popupTargetPageOption');
    }
    get popupTargetPageUrlArray() {
        return this.simpleSettingsForm.get('popupTargetPageUrlArray') as FormArray;
    }
    get adBudgetOption() {
        return this.simpleSettingsForm.get('adBudgetOption');
    }
    get adBudget() {
        return this.simpleSettingsForm.get('adBudget');
    }
    get pictureCreateOption() {
        return this.simpleSettingsForm.get('pictureCreateOption');
    }

    get popupDeviceOption() {
        return this.advancedSettingsForm.get('popupDeviceOption');
    }
    get popupTiming() {
        return this.advancedSettingsForm.get('popupTiming');
    }
    get popupPeriodOption() {
        return this.advancedSettingsForm.get('popupPeriodOption');
    }
    get popupIntervalOption() {
        return this.advancedSettingsForm.get('popupIntervalOption');
    }
    get popupInterval() {
        return this.advancedSettingsForm.get('popupInterval');
    }

    /**
     * ポップアップ表示抑制時間(日)
     */
    get popupIntervalDays() {
        return this.advancedSettingsForm.get('popupIntervalDays');
    }

    /**
     * ポップアップ表示抑制時間(時間)
     */
    get popupIntervalHours() {
        return this.advancedSettingsForm.get('popupIntervalHours');
    }

    /**
     * ポップアップ表示抑制時間(分)
     */
    get popupIntervalMinutes() {
        return this.advancedSettingsForm.get('popupIntervalMinutes');
    }

    get publicationStartDate() {
        return this.advancedSettingsForm.get('publicationStartDate');
    }
    get publicationStopDate() {
        return this.advancedSettingsForm.get('publicationStopDate');
    }
    // ポップアップ名の文字数バリデーションチェックの結果を取得
    get adTitleMaxLength() {
        return this.publicationSettingsService.adTitleMaxLength;
    }
    // Q3のバリデーションチェック結果を取得
    get popupTargetPageUrlValid() {
        return this.publicationSettingsService.popupTargetPageUrlValid;
    }
    // バナー画像の有無を取得
    get pictureUpFlag() {
        return this.publicationSettingsService.pictureUpFlag;
    }
    get popupIntervalValid() {
        return this.publicationSettingsService.popupIntervalValid;
    }
    get popupTimingValid() {
        return this.publicationSettingsService.popupTimingValid;
    }


    constructor(
        private preview: PreviewBgFrameService,
        private route: ActivatedRoute,
        private publicationSettingsService: PublicationSettingsService,
        private fb: FormBuilder,
        private router: Router,
        private messageService: MessageService,
    ) {
        this.simpleSettingsForm.patchValue({
            adTitle: 'ポップアップのタイトルを入力してください。',
        });
    }

    ngOnInit() {
        // URLからadIdを取得
        this.adId = this.route.snapshot.paramMap.get('adId');
        this.getAdInfo(this.adId);
        this.publicationSettingsService.openPublicationSettings = true;
        if (this.adId === 'create') {
            this.publicationSettingsService.pictureCreateHide = false;
            this.publicationSettingsService.adTitleMaxLength = false;
        }
    }

    ngOnDestroy() {
        this.publicationSettingsService.openPublicationSettings = false;
        this.publicationSettingsService.previewHidden = false;
    }

   /** 詳細設計の表示切り替え */
    onHiddenComponent(): void {
        this.hidden = !this.hidden;
    }

    /** プレビュー画面の表示 */
    previewPop(): void {
        this.preview.open(PreviewBgItemComponent);
        // ポップアップ表示をディレイ
        setTimeout(() => {
            this.publicationSettingsService.previewHidden = true;
        }, this.popupTiming.value * 1000);
    }

    /**
     * 広告IDに該当する広告情報を取得する
     * @param string 広告ID
     * @returns void
     */
    getAdInfo(adId: string): void {
        this.publicationSettingsService.getAdInfo(adId).subscribe(res => {
            this.adInfo = res;

            // フォームに値を分配
            this.simpleSettingsForm.patchValue({
                adTitle: res.adTitle,
                popupTargetSegmentOption: res.popupTargetSegmentOption,
                appealPageOption: res.appealPageOption,
                appealPageUrl: res.appealPageUrl,
                popupTargetPageOption: res.popupTargetPageOption,
                adBudgetOption: res.adBudgetOption,
                adBudget: res.adBudget,
                pictureCreateOption: res.pictureCreateOption,
            });
            this.advancedSettingsForm.patchValue({
                popupDeviceOption: res.popupDeviceOption,
                popupTiming: res.popupTiming,
                popupPeriodOption: res.popupPeriodOption,
                popupIntervalOption: null,
                popupInterval: res.popupInterval,
                popupIntervalDays: res.popupIntervalDays,
                popupIntervalHours: res.popupIntervalHours,
                popupIntervalMinutes: res.popupIntervalMinutes,
                publicationStartDate: res.publicationStartDate,
                publicationStopDate: res.publicationStopDate
            });
            this.publicationSettingsService.appealPageUrlTarget = this.adInfo.appealPageUrlTarget;
            this.publicationSettingsService.viewConversionFlag = this.adInfo.viewConversionFlag;
            this.publicationSettingsService.targetUrl = this.adInfo.popupTargetPageUrl;
            this.publicationSettingsService.appealUrl = this.adInfo.appealPageUrl;
            this.publicationSettingsService.canvas300 = this.adInfo.pictureData;
            this.publicationSettingsService.canvasOrigin = this.adInfo.pictureDataBig;
            this.publicationSettingsService.upload300 = this.adInfo.pictureData;
            this.publicationSettingsService.uploadOrigin = this.adInfo.pictureData;
            if (this.adId === 'create' && this.publicationSettingsService.targetUser != null) {
                this.simpleSettingsForm.patchValue({
                    // ダッシュボードからの引数とラジオボタンで利用したい値の差を直すため+2する
                    popupTargetSegmentOption: this.publicationSettingsService.targetUser + 2
                });
                this.publicationSettingsService.targetUser = null;
            }
            if (this.pictureCreateOption.value === 1) {
                this.publicationSettingsService.pictureCreateHide = false;
            } else if (this.pictureCreateOption.value === 2) {
                this.publicationSettingsService.pictureCreateHide = true;

                setTimeout(() => {
                    const uploadButton = document.getElementsByClassName('ui-fileupload-choose')[0];
                    const position = uploadButton.getBoundingClientRect();
                    this.publicationSettingsService.recommendText.top = position.top + window.scrollY;
                    this.publicationSettingsService.recommendText.left = position.left + position.width + window.scrollX;
                }, 100);
            }
            // Q3URL入力テキストボックスの追加
            this.popupTargetPageUrlArray.removeAt(0);
            for (let i = 0; i <= 12; i++) {
                if (res.popupTargetPageUrl[i]) {
                    this.popupTargetPageUrlArray.push(this.fb.control(res.popupTargetPageUrl[i], Validators.pattern(this.urlRegExp)));
                } else {
                    if (i < 6) {
                        this.popupTargetPageUrlArray.push(this.fb.control('', Validators.pattern(this.urlRegExp)));
                    }
                }
            }
            // ポップアップ名の文字数バリデーション
            if (this.adId !== 'create') {
                if (this.adInfo.adTitle.length > 30) {
                    this.publicationSettingsService.adTitleMaxLength = true;
                }
            }
            this.publicationSettingsService.firstGetAdInfo = true;
        });
    }

    /**
     * 画面で入力した内容で広告データを更新する
     * @event 公開するボタンクリック時
     * @param AdInfo 出稿設定画面で入力した広告データ
     * @returns void
     */
    onClick(adInfo: AdInfo, picTemp: PicTemp): void {
        this.adInfo.adTitle = this.adTitle.value;
        this.adInfo.popupTargetSegmentOption = this.popupTargetSegmentOption.value;
        this.adInfo.appealPageOption = this.appealPageOption.value;
        this.adInfo.appealPageUrl = this.appealPageUrl.value;
        this.adInfo.appealPageUrlTarget = this.publicationSettingsService.appealPageUrlTarget;
        this.adInfo.viewConversionFlag = this.publicationSettingsService.viewConversionFlag;
        this.adInfo.popupTargetPageOption = this.popupTargetPageOption.value;
        this.adInfo.popupTargetPageUrl = this.popupTargetPageUrlArray.value.filter(word => word !== null && word.trim() !== '');
        this.adInfo.adBudgetOption = this.adBudgetOption.value;
        this.adInfo.adBudget = this.adBudget.value;
        this.adInfo.pictureCreateOption = this.pictureCreateOption.value;
        this.adInfo.popupDeviceOption = this.popupDeviceOption.value;
        this.adInfo.popupTiming = this.popupTiming.value;
        this.adInfo.popupPeriodOption = this.popupPeriodOption.value;
        this.adInfo.popupIntervalOption = null;
        this.adInfo.popupInterval = this.popupInterval.value;
        this.adInfo.popupIntervalDays = this.popupIntervalDays.value;
        this.adInfo.popupIntervalHours = this.popupIntervalHours.value;
        this.adInfo.popupIntervalMinutes = this.popupIntervalMinutes.value;

        if (this.adId === 'create') {
            this.publicationSettingsService.createAdInfo(adInfo).subscribe(res => this.showConfirm(res));
        } else {
            this.publicationSettingsService.updateAdInfo(adInfo, this.adId).subscribe(res => this.showConfirm(res));
        }
    }

    /**
     * 出稿設定の変更結果をメッセージで出力する
     * @event 公開ボタンクリック時
     * @returns void
     */
    showConfirm(res: ResponseData) {
        this.messageService.clear();
        if (res.result === 'OK') {
            this.messageService.add({
                key: 'pub-ok',
                sticky: false,
                severity: 'success',
                summary: '公開しました',
                detail: '公開できました',
                life: 2000
            });
            this.publicationSettingsService.openPublicationSettings = false;
        } else {
            this.messageService.add({
                key: 'error',
                sticky: false,
                severity: 'error',
                summary: 'エラー',
                detail: res.resultMessage,
                life: 2000
            });
        }
    }
}
