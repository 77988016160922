import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ResponseData } from '../response-data';
import { MessageService, ConfirmationService } from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';

import { ReportService } from './report.service';
import { Report } from './report';
import { Router } from '@angular/router';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit, OnChanges {
    constructor(
        private reportService: ReportService,
        private domSanitizer: DomSanitizer,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public changeDetectorRef: ChangeDetectorRef,
        private router: Router
    ) {}

    // 出稿先ユーザーのラベル画像用
    labelSrc = [
        'assets/images/label-all/label-t.png',
        'assets/images/label-b/label-b.png',
        'assets/images/label-g/label-g.png',
        'assets/images/label-r/label-r.png'
    ];
    labelSrcSet = [
        'assets/images/label-all/label-t@2x.png 2x, assets/images/label-all/label-t@3x.png 3x',
        'assets/images/label-b/label-b@2x.png 2x, assets/images/label-b/label-b@3x.png 3x',
        'assets/images/label-g/label-g@2x.png 2x, assets/images/label-g/label-g@3x.png 3x',
        'assets/images/label-r/label-r@2x.png 2x, assets/images/label-r/label-r@3x.png 3x',
    ];

    // レポート情報用
    reports: Report[];
    adState = { stateFlag: 0 };

    // レポートの存在チェック用
    nonReport = false;

    ngOnInit() {
        this.reportService.getReportType().subscribe(res => {
            if (res === '2') {
              this.router.navigate(['/report-landscape']);
            } else {
                this.getReportInfo();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        throw new Error('Method not implemented.');
    }

    /** reportServiceから取得した値をreportsに設定。 */
    getReportInfo(): void {
        this.reportService.getReportInfo('', '').subscribe(res => {
            this.nonReport = false;
            this.reports = res;
            this.reports.forEach(report => {
                // report.pictureDataBig = this.domSanitizer.bypassSecurityTrustUrl(report.pictureDataBig);
                report.testBigUrl = this.domSanitizer.bypassSecurityTrustUrl(report.pictureDataBig + ' 2x');
            });
            if ( this.reports.length === 0) {
                this.nonReport = true;
            }
        });
    }

    /**
     * 出稿状態の変更を行う
     * adId 広告ID
     * state 出稿の停止情報
     */
    reportStop(adId: string, state: number, event) {
        this.adState.stateFlag = state;
        this.reportService.updateReportState(this.adState, adId).subscribe(() => {
            this.reInit();
        });
    }

    /**
     * 出稿内容のコピーを行う
     * adId 広告ID
     */
    reportCopy(adId: string) {
        this.confirmationService.confirm({
            message: '広告をコピーしますか？',
            header: 'コピー確認',
            key: 'dialogConfirm',
            acceptLabel: 'はい',
            rejectLabel: 'いいえ',
            accept: () => {
                this.reportService.copyReport(this.reports.find(i => i.adId === Number(adId)), adId).subscribe(res => {
                    this.showMsg(res, {
                        successSummary: 'コピーしました',
                        successDetail: 'コピーしました。',
                        errorSummary: 'コピーエラー'
                    });
                });
            },
            reject: () => {

            }
        });
   }

    /**
     * 各レポートAPIでの変更結果をメッセージで出力する
     * @event 変更ボタンクリック時
     * @returns void
     */
    showMsg(res: ResponseData, msgProperty: any) {
        this.messageService.clear();
        if (res.result === 'OK') {
            this.messageService.add({
                key: 'dialogReInit',
                sticky: false,
                severity: 'success',
                summary: msgProperty.successSummary,
                detail: msgProperty.successDetail,
                life: 2000
            });
        } else {
            this.messageService.add({
                key: 'dialogReInit',
                sticky: false,
                severity: 'error',
                summary: msgProperty.errorSummary,
                detail: res.resultMessage,
                life: 2000
            });
        }
        setTimeout(() => {
            this.messageService.clear('dialogReInit');
            this.reInit();
        }, 2000);
    }

    resultDelete(res: ResponseData) {
        this.messageService.clear('dialogReInit');
        if (res.result === 'OK') {
            this.messageService.add({
                key: 'dialogReInit',
                sticky: false,
                severity: 'success',
                summary: '削除完了',
                detail: '削除しました。',
                life: 2000
            });
        } else {
            this.messageService.add({
                key: 'dialogReInit',
                sticky: false,
                severity: 'error',
                summary: '削除エラー',
                detail: res.resultMessage,
                life: 2000
            });
        }
        setTimeout(() => {
            this.messageService.clear('dialogReInit');
            this.reInit();
        }, 2000);
    }

    reInit() {
        this.ngOnInit();
    }

    deleteReport(adId: string, adTitle: string) {
        this.confirmationService.confirm({
            message: '[' + adTitle + ']広告を削除しますか？',
            header: '削除確認',
            key: 'dialogConfirm',
            acceptLabel: 'はい',
            rejectLabel: 'いいえ',
            accept: () => {
                this.reportService.deleteReport(adId).subscribe(res => {
                    this.resultDelete(res);
                });
            },
            reject: () => {

            }
        });
    }

    changeReportType(reportType: string) {
        this.reportService.changeReportType(reportType);
    }
}
