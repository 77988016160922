import { DatePipe } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { SessionService } from '../session.service';
import { DashboardData } from './dashboard.data';
import { DashboardService } from './dashboard.service';
import { PublicationSettingsService } from '../publication-settings/publication-settings.service';
import { Router } from '@angular/router';
import '../../assets/js/ocnk.popup.js';

declare var addOcnkPopupScript: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [SessionService],
})
export class DashboardComponent implements OnInit {


  constructor(private siteService: DashboardService, private sessionService: SessionService, @Inject(LOCALE_ID) private locale: string
    ,         private datePipe: DatePipe, private publicationSettingsService: PublicationSettingsService, private router: Router) { }
  calendarFormat: any;
  public calendarDate = new Date();
  dashboardPanelTitle: string[][] = [['初めての人', '興味のある人', '買い物しそうな人'], ['初めて訪問した人', '商品に興味があり調べている', '購入意欲が高い']];
  dashboardDataList = new DashboardData();
  returnprm: any;
  month: any;
  monthget: any;
  state: any[];
  clickCountClass: Array<boolean> = [];
  clickPercentClass: Array<boolean> = [];
  onselect(e) {
    this.month = this.getmonth();
    this.returnprm = this.sessionService.getDashboardUrl(this.month);
    this.getSiteInfo(this.returnprm);
  }

  ngOnInit() {
    this.monthget = this.getmonth();
    this.returnprm = this.sessionService.getDashboardUrl(this.monthget);
    this.getSiteInfo(this.returnprm);
    this.calendarFormat = {
      firstDayOfWeek: 0,
      dayNames: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
      dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      today: 'This month',
      clear: 'Clear',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'Wk'
    };

    addOcnkPopupScript();
  }
  getSiteInfo(key): void {
    this.siteService.getSiteInfo(key).subscribe(json => {
    this.dashboardDataList = json;
    });
  }
  getmonth() {
    this.month = this.datePipe.transform(this.calendarDate, 'yyyy/MM');
    this.month = this.month.replace('/', '');
    return this.month;
  }

  pageChange(targetUser: number) {
    this.publicationSettingsService.targetUser = targetUser;
    this.router.navigate(['/publication-settings/create']);
  }
}
