import { MenuItem } from 'primeng/api';
import {Component, Input, OnInit} from '@angular/core';
import { ValueShereService } from '../value-shere.service';
import {PublicationSettingsService} from '../publication-settings/publication-settings.service';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {

    @Input() sideMenuActive: boolean;
    constructor(
        private publicationSettingsService: PublicationSettingsService,
        private router: Router,
        private valueShareService: ValueShereService,
        private route: ActivatedRoute
    ) { }
  items: MenuItem[];

  ngOnInit() {
      this.items = [
        {
            label: 'ダッシュボード',
            styleClass: 'test',
            icon: 'fas fa-th-large white',
            routerLink: '',
            routerLinkActiveOptions: 'active',
        },
          {
              label: '基本設定',
              icon: 'pi pi-fw pi-cog',
              routerLink: 'basic-settings',
              routerLinkActiveOptions: 'active',
          },
          {
              label: '出稿',
              icon: 'pi pi-external-link',
              routerLink: 'publication-settings',
              routerLinkActiveOptions: 'active',
          },
          {
              label: 'レポート',
              icon: 'pi pi-chart-bar',
              routerLink: 'report',
              routerLinkActiveOptions: 'active',
          },
      ];
  }
  get isError() {
    return this.valueShareService.isError;
  }

  publicationSettingsOpen() {
    if ( this.publicationSettingsService.openPublicationSettings === false ) {
        this.router.navigate(['/publication-settings/create']);
    }
  }
}
