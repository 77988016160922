import { Injectable} from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Report } from './report/report';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(private datePipe: DatePipe) {}

  makeReportExcel(reportList: Report[], shopName: string, startDate: string, endDate: string) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Report', {
      properties: {showGridLines: false},
      views: [{
        xSplit: 0,
        ySplit: 0,
        showGridLines: false
      }]
    });
    const dateFormat = 'yyyyMMddHHmmss';
    const now = this.datePipe.transform(new Date(), dateFormat);
    const reportFile = '[Report]' + shopName + '_' + now + '.xlsx';
    const colHeaders = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'
    , 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W'];
    const colRates = ['H', 'J', 'N', 'P', 'T', 'V'];
    const colRatesWidth = 8;
    const colAmount = ['K', 'Q', 'W'];
    const colAmountWidth = 10.5;

    let termStr: string;
    worksheet.properties.showGridLines = false;

    if ((startDate === null || startDate === undefined || startDate.trim() === '') &&
      (endDate === null || endDate === undefined || endDate.trim() === '')) {
      termStr = '全期間';
    }

    if (startDate !== null && startDate !== undefined && startDate.trim() !== '') {
      termStr = startDate + ' ~ ';
    }

    if (endDate !== null && endDate !== undefined && endDate.trim() !== '') {
      termStr += endDate;
    }

    const reportTitle = shopName + ' Report (表示期間：' + termStr + ')';

    worksheet.mergeCells('A1:W1');
    worksheet.getCell('A1').value = reportTitle;
    worksheet.getCell('A1').alignment = {horizontal: 'left', vertical: 'middle'};

    worksheet.getCell('A1').font = {
      name: 'MS UI Gothic',
      family: 4,
      size: 12,
      bold: true
    };

    worksheet.addRow([]);

    worksheet.mergeCells('A3:A4');
    worksheet.getCell('A3').value = '広告ID';
    worksheet.mergeCells('B3:B4');
    worksheet.getCell('B3').value = '広告タイトル';

    worksheet.mergeCells('C3:C4');
    worksheet.getCell('C3').value = '状態';
    worksheet.mergeCells('D3:D4');
    worksheet.getCell('D3').value = 'ターゲット';
    worksheet.mergeCells('E3:E4');
    worksheet.getCell('E3').value = '広告費';
    worksheet.mergeCells('F3:K3');
    worksheet.getCell('F3').value = '全体';
    worksheet.mergeCells('L3:Q3');
    worksheet.getCell('L3').value = 'PC';
    worksheet.mergeCells('R3:W3');
    worksheet.getCell('W3').value = 'Mobile';

    worksheet.getCell('F4').value = '表示回数';
    worksheet.getCell('G4').value = 'クリック回数';
    worksheet.getCell('H4').value = 'クリック率';
    worksheet.getCell('I4').value = '受注回数';
    worksheet.getCell('J4').value = 'CV率';
    worksheet.getCell('K4').value = '受注金額';
    worksheet.getCell('L4').value = '表示回数';
    worksheet.getCell('M4').value = 'クリック回数';
    worksheet.getCell('N4').value = 'クリック率';
    worksheet.getCell('O4').value = '受注回数';
    worksheet.getCell('P4').value = 'CV率';
    worksheet.getCell('Q4').value = '受注金額';
    worksheet.getCell('R4').value = '表示回数';
    worksheet.getCell('S4').value = 'クリック回数';
    worksheet.getCell('T4').value = 'クリック率';
    worksheet.getCell('U4').value = '受注回数';
    worksheet.getCell('V4').value = 'CV率';
    worksheet.getCell('W4').value = '受注金額';

    const headerFont = {
      name: 'MS UI Gothic',
      family: 4,
      size: 10,
      bold: true
    };

    worksheet.getRow(1).height = 17;
    worksheet.getRow(3).height = 16;
    worksheet.getRow(4).height = 16;

    colHeaders.map(col => {
      worksheet.getCell(col + '3').alignment = {horizontal: 'center', vertical: 'middle'};
      worksheet.getCell(col + '4').alignment = {horizontal: 'center', vertical: 'middle'};
      worksheet.getCell(col + '3').font = headerFont;
      worksheet.getCell(col + '4').font = headerFont;
      worksheet.getCell(col + '3').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FFCCCCCC'}
      };
      worksheet.getCell(col + '4').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FFCCCCCC'}
      };

      worksheet.getCell(col + '3').border = {
        top: {style: 'thick'},
        left: {style: col === 'A' ? 'thick' : 'thin'},
        bottom: {style: 'thin'},
        right: {style: col === 'W' ? 'thick' : 'thin'}
      };
      worksheet.getCell(col + '4').border = {
        top: {style: col <= 'E' ? 'thick' : 'thin'},
        left: {style: col === 'A' ? 'thick' : 'thin'},
        bottom: {style: 'double'},
        right: {style: col === 'W' ? 'thick' : 'thin'}
      };
    });

    reportList.map((report, idx) => {
      const i = idx + 5;
      /* const popupImage = workbook.addImage({
        base64: report.pictureData,
        extension: 'png'
      }); */
      let userState = '';
      let popupCondition = '';

      if (report.publicationState === '1') {
        popupCondition = '公開中';
      } else {
        popupCondition = '停止中';
      }

      switch (report.popupTargetSegmentOption) {
        case 1: {
          userState = 'すべての人';
          break;
        }
        case 2: {
          userState = '初めての人';
          break;
        }
        case 3: {
          userState = '興味のある人';
          break;
        }
        case 4: {
          userState = '買い物しそうな人';
          break;
        }
      }

      worksheet.getCell('A' + i).value = report.adId;
      worksheet.getCell('B' + i).value = report.adTitle;
      // worksheet.addImage(popupImage, 'D' + i + ':D' + i);
      worksheet.getCell('C' + i).value = popupCondition;
      worksheet.getCell('D' + i).value = userState;
      worksheet.getCell('E' + i).value = report.adCosts;
      worksheet.getCell('E' + i).numFmt = '"￥"#,##0';
      worksheet.getCell('E' + i).alignment = {horizontal: 'right'};
      worksheet.getCell('F' + i).value = report.impression;
      worksheet.getCell('F' + i).numFmt = '#,##0回';
      worksheet.getCell('F' + i).alignment = {horizontal: 'right'};
      worksheet.getCell('G' + i).value = report.clickCount;
      worksheet.getCell('G' + i).numFmt = '#,##0回';
      worksheet.getCell('G' + i).alignment = {horizontal: 'right'};
      worksheet.getCell('H' + i).value = Number(report.clickRate) / 100;
      worksheet.getCell('H' + i).numFmt = '0.00%';
      worksheet.getCell('H' + i).alignment = {horizontal: 'right'};

      if (report.viewConversionFlag === true) {
        worksheet.getCell('I' + i).value = report.orderCnt;
        worksheet.getCell('I' + i).numFmt = '#,##0回';
        worksheet.getCell('I' + i).alignment = {horizontal: 'right'};
        worksheet.getCell('J' + i).value = Number(report.orderRate) / 100;
        worksheet.getCell('J' + i).numFmt = '0.00%';
        worksheet.getCell('J' + i).alignment = {horizontal: 'right'};
        worksheet.getCell('K' + i).value = report.orderAmt;
        worksheet.getCell('K' + i).numFmt = '"￥"#,##0';
        worksheet.getCell('K' + i).alignment = {horizontal: 'right'};
      } else {
        worksheet.getCell('I' + i).value = '-';
        worksheet.getCell('I' + i).alignment = {horizontal: 'right'};
        worksheet.getCell('J' + i).value = '-';
        worksheet.getCell('J' + i).alignment = {horizontal: 'right'};
        worksheet.getCell('K' + i).value = '-';
        worksheet.getCell('K' + i).alignment = {horizontal: 'right'};
      }

      worksheet.getCell('L' + i).value = report.pcCnt;
      worksheet.getCell('L' + i).numFmt = '#,##0回';
      worksheet.getCell('L' + i).alignment = {horizontal: 'right'};
      worksheet.getCell('M' + i).value = report.pcUserClickCount;
      worksheet.getCell('M' + i).numFmt = '#,##0回';
      worksheet.getCell('M' + i).alignment = {horizontal: 'right'};
      worksheet.getCell('N' + i).value = Number(report.pcUserClickRate) / 100;
      worksheet.getCell('N' + i).numFmt = '0.00%';
      worksheet.getCell('N' + i).alignment = {horizontal: 'right'};

      if (report.viewConversionFlag === true) {
        worksheet.getCell('O' + i).value = report.orderPcCnt;
        worksheet.getCell('O' + i).numFmt = '#,##0回';
        worksheet.getCell('O' + i).alignment = {horizontal: 'right'};
        worksheet.getCell('P' + i).value = Number(report.orderPcRate) / 100;
        worksheet.getCell('P' + i).numFmt = '0.00%';
        worksheet.getCell('P' + i).alignment = {horizontal: 'right'};
        worksheet.getCell('Q' + i).value = report.orderPcAmt;
        worksheet.getCell('Q' + i).numFmt = '"￥"#,##0';
        worksheet.getCell('Q' + i).alignment = {horizontal: 'right'};
      } else {
        worksheet.getCell('O' + i).value = '-';
        worksheet.getCell('O' + i).alignment = {horizontal: 'right'};
        worksheet.getCell('P' + i).value = '-';
        worksheet.getCell('P' + i).alignment = {horizontal: 'right'};
        worksheet.getCell('Q' + i).value = '-';
        worksheet.getCell('Q' + i).alignment = {horizontal: 'right'};
      }

      worksheet.getCell('R' + i).value = report.mobileCnt;
      worksheet.getCell('R' + i).numFmt = '#,##0回';
      worksheet.getCell('R' + i).alignment = {horizontal: 'right'};
      worksheet.getCell('S' + i).value = report.phoneUserClickCount;
      worksheet.getCell('S' + i).numFmt = '#,##0回';
      worksheet.getCell('S' + i).alignment = {horizontal: 'right'};
      worksheet.getCell('T' + i).value = Number(report.phoneUserClickRate) / 100;
      worksheet.getCell('T' + i).numFmt = '0.00%';
      worksheet.getCell('T' + i).alignment = {horizontal: 'right'};

      if (report.viewConversionFlag === true) {
        worksheet.getCell('U' + i).value = report.orderMobileCnt;
        worksheet.getCell('U' + i).numFmt = '#,##0回';
        worksheet.getCell('U' + i).alignment = {horizontal: 'right'};
        worksheet.getCell('V' + i).value = Number(report.orderMobileRate) / 100;
        worksheet.getCell('V' + i).numFmt = '0.00%';
        worksheet.getCell('V' + i).alignment = {horizontal: 'right'};
        worksheet.getCell('W' + i).value = report.orderMobileAmt;
        worksheet.getCell('W' + i).numFmt = '"￥"#,##0';
        worksheet.getCell('X' + i).alignment = {horizontal: 'right'};
      } else {
        worksheet.getCell('U' + i).value = '-';
        worksheet.getCell('U' + i).alignment = {horizontal: 'right'};
        worksheet.getCell('V' + i).value = '-';
        worksheet.getCell('V' + i).alignment = {horizontal: 'right'};
        worksheet.getCell('W' + i).value = '-';
        worksheet.getCell('W' + i).alignment = {horizontal: 'right'};
      }

      colHeaders.map(col => {
        worksheet.getCell(col + i).border = {
          top: {style: 'thin'},
          left: {style: col === 'A' ? 'thick' : 'thin'},
          bottom: {style: idx === reportList.length - 1 ? 'thick' : 'thin'},
          right: {style: col === 'W' ? 'thick' : 'thin'}
        };

        worksheet.getCell(col + i).font = {
          name: 'MS UI Gothic',
          family: 4,
          size: 10,
          bold: false
        };
      });

      worksheet.getRow(i).height = 15;
      worksheet.getRow(i).alignment = {vertical: 'middle'};
    });

    colHeaders.map(col => {
      if (col === 'B') {
        worksheet.getColumn(col).width = 22;
      }

      if (col === 'C') {
        worksheet.getColumn(col).width = 6.5;
      }

      if (col === 'D') {
        worksheet.getColumn(col).width = 13;
      }

      if (col >= 'E' && col <= 'W') {
        if (colRates.includes(col)) {
          worksheet.getColumn(col).width = colRatesWidth;
        } else if (colAmount.includes(col)) {
          worksheet.getColumn(col).width = colAmountWidth;
        } else {
          worksheet.getColumn(col).width = 10.5;
        }
      }
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      fs.saveAs(blob, reportFile);
    });
  }
}
