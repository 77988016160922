export class Const {
    static readonly apiUrl = 'api/front/';
    static readonly serverDomain = 'https://popup.elsa.contact.co.jp/';
    static readonly dashboardUrl = Const.apiUrl + 'dashboard';
    static readonly shopUrl = Const.apiUrl + 'basicSetting';
    static readonly adInfoUrl = Const.apiUrl + 'publicationSetting';
    static readonly pictureUrl = 'api/front/templates';
    static readonly reportUrl = Const.apiUrl + 'report';
    static readonly reportCopyUrl = Const.apiUrl + 'report/{adId}';
    static readonly reportStopUrl = Const.apiUrl + 'report/{adId}/doActive';
    static readonly reportDeleteUrl = Const.apiUrl + 'report/{adId}/delete';
    static readonly scriptStr = '<script>\nvar popupService = {\nserverDomain:{serverDomain},\nshopId: {shopId}\n};\n</script>\n<link rel="stylesheet" type="text/css" href="' + Const.serverDomain + 'api/css/popup.css" />\n <script type="text/javascript" src="' + Const.serverDomain + 'api/js/popup.js"></script>';
}
