import { Component, OnInit, Input, OnChanges} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AdInfo } from '../ad-info';
import { ConstText } from '../const-text';
import { DatePipe } from '@angular/common';
import { PublicationSettingsService } from '../publication-settings.service';
@Component({
    selector: 'app-advanced-settings',
    templateUrl: './advanced-settings.component.html',
    styleUrls: ['./advanced-settings.component.scss', '../publication-settings.component.scss'],
})
export class AdvancedSettingsComponent implements OnInit, OnChanges {
    // publication-settingsのコンポーネントで定義した変数
    @Input() adInfo: AdInfo;

    // フォームの情報取得
    @Input('advancedSettingsForm') advancedSettingsForm: any;
    get popupDeviceOption() {
        return this.advancedSettingsForm.get('popupDeviceOption');
    }
    get popupTiming() {
        return this.advancedSettingsForm.get('popupTiming');
    }
    get popupPeriodOption() {
        return this.advancedSettingsForm.get('popupPeriodOption');
    }
    get popupIntervalOption() {
        return this.advancedSettingsForm.get('popupIntervalOption');
    }
    get popupInterval() {
        return this.advancedSettingsForm.get('popupInterval');
    }

    /**
     * ポップアップ表示抑制時間(日)
     */
    get popupIntervalDays() {
        return this.advancedSettingsForm.get('popupIntervalDays');
    }

    /**
     * ポップアップ表示抑制時間(時間)
     */
    get popupIntervalHours() {
        return this.advancedSettingsForm.get('popupIntervalHours');
    }

    /**
     * ポップアップ表示抑制時間(分)
     */
    get popupIntervalMinutes() {
        return this.advancedSettingsForm.get('popupIntervalMinutes');
    }

    get publicationStartDate() {
        return this.advancedSettingsForm.get('publicationStartDate');
    }
    get publicationStopDate() {
        return this.advancedSettingsForm.get('publicationStopDate');
    }
    // ポップアップ名の文字数バリデーションチェックの結果を取得
    get popupIntervalValid() {
         return this.publicationSettingsService.popupIntervalValid;
    }
    get popupTimingValid() {
        return this.publicationSettingsService.popupTimingValid;
   }
    constructor( public datePipe: DatePipe, private fb: FormBuilder, private publicationSettingsService: PublicationSettingsService) {}

    // カレンダー用
    date: Date[];
    minDate: Date;
    ja = ConstText.ja;
    pastSettingDate: string;
    viewStartDate: string;
    viewStopDate: string;
    startDate: Date;
    stopDate: Date;
    // ラジオボタン用
    q6Data = ConstText.q6Data;
    q7Data = ConstText.q7Data;
    q8Data = ConstText.q8Data;

    selPopupInvervalDays = this.makeSelectOptions(0, 10, 1, '日');
    selPopupInvervalHours = this.makeSelectOptions(0, 23, 1, '時間');
    selPopupInvervalMinutes = this.makeSelectOptions(0, 55, 5, '分');
    // ポップアップインターバルバリデーション用
    popupIntervalMin = false;
    // ポップアップタイミングバリデーション用
    popupTimingMin = false;
    ngOnInit() {
        this.minDate = new Date();
    }
    /**
     * 簡易設定コンポーネントから値を受け取った時にカレンダー用の値があればデフォルトデータにセット
     */
    ngOnChanges(): void {
        if (this.adInfo !== null && this.publicationSettingsService.firstGetAdInfo === true && this.publicationStartDate.value != null) {
            this.date = this.makeToDate(this.publicationStartDate.value, this.publicationStopDate.value);
            // apiから送られてきたPeriodOptionが1なら開始時間を変数に格納
            if (this.popupPeriodOption.value === '1') {
                this.pastSettingDate = this.publicationStartDate.value;
            }
            this.viewStartDate = this.datePipe.transform(this.date[0], 'yyyy年MM月dd日（EE）HH時mm分');
            // tslint:disable-next-line: max-line-length
            this.viewStartDate = this.viewStartDate.replace('Mon', '月').replace('Tue', '火').replace('Wed', '水').replace('Thu', '木').replace('Fri', '金').replace('Sat', '土').replace('Sun', '日');
            // 終了時間が存在したら変換を行う
            if (this.publicationStopDate.value != null) {
                this.viewStopDate = this.datePipe.transform(this.date[1], 'yyyy年MM月dd日（EE）HH時mm分');
            // tslint:disable-next-line: max-line-length
                this.viewStopDate = this.viewStopDate.replace('Mon', '月').replace('Tue', '火').replace('Wed', '水').replace('Thu', '木').replace('Fri', '金').replace('Sat', '土').replace('Sun', '日');
            }
        }
    }
    /**
     * カレンダーから選択されたデータの取得
     */
    clickChangeDate() {
        // ラジオボタンが毎回出すのとき
        if (this.popupPeriodOption.value === '1' ) {
             // pastSettingDateが存在する時apiに送る開始日を元々設定していた値に変更
            if (this.pastSettingDate !== undefined) {
                this.adInfo.publicationStartDate = this.pastSettingDate;
                this.adInfo.publicationStopDate = null;
            } else {// pastSettingDateが存在しない場合は現在の日付を入れるためnullでapiに送る
                this.adInfo.publicationStartDate = null;
                this.adInfo.publicationStopDate = null;
            }
        }  else if (this.popupPeriodOption.value === '2') { // ラジオボタンが期間を設定の場合formの値をapiに送る
            this.adInfo.publicationStartDate = this.publicationStartDate.value;
            this.adInfo.publicationStopDate = this.publicationStopDate.value;
        }
    }
    /** カレンダーから選択されたデータの取得 */
    onChangeDate() {
        // カレンダーから取得した開始時間がnullではない場合date型からstring型へviewとapiのために変換
            if (this.date[0] != null ) {
            this.adInfo.publicationStartDate = this.datePipe.transform(this.date[0], 'yyyyMMddHHmm00');
            this.publicationStartDate.value = this.datePipe.transform(this.date[0], 'yyyyMMddHHmm00');
            this.viewStartDate = this.datePipe.transform(this.date[0], 'yyyy年MM月dd日（EE）HH時mm分');
            // tslint:disable-next-line: max-line-length
            this.viewStartDate = this.viewStartDate.replace('Mon', '月').replace('Tue', '火').replace('Wed', '水').replace('Thu', '木').replace('Fri', '金').replace('Sat', '土').replace('Sun', '日');
            }
        // カレンダーから取得した終了時間がnullではない場合date型からstring型へviewとapiのために変換
            if (this.date[1] != null) {
            this.adInfo.publicationStopDate = this.datePipe.transform(this.date[1], 'yyyyMMddHHmm00');
            this.publicationStopDate.value = this.datePipe.transform(this.date[1], 'yyyyMMddHHmm00');
            this.viewStopDate = this.datePipe.transform(this.date[1], 'yyyy年MM月dd日（EE）HH時mm分');
            // tslint:disable-next-line: max-line-length
            this.viewStopDate = this.viewStopDate.replace('Mon', '月').replace('Tue', '火').replace('Wed', '水').replace('Thu', '木').replace('Fri', '金').replace('Sat', '土').replace('Sun', '日');
            } else {
                this.viewStopDate = null;
                this.adInfo.publicationStopDate = null;
            }
          }
    /** DBから取得した値をDate型に変換 */
    makeToDate(startString, stopStoring) {
        if (startString != null) {
        // tslint:disable-next-line: max-line-length
        startString = startString.slice(0, 4) + '-' + startString.slice(4, 6) + '-' + startString.slice(6, 8) + 'T' + startString.slice(8, 10) + ':' + startString.slice(10, 12) + ':' + startString.slice(12, 14);
        this.startDate = new Date(startString);
    }
        if (stopStoring != null) {
        // tslint:disable-next-line: max-line-length
        stopStoring = stopStoring.slice(0, 4) + '-' + stopStoring.slice(4, 6) + '-' + stopStoring.slice(6, 8) + 'T' + stopStoring.slice(8, 10) + ':' + stopStoring.slice(10, 12) + ':' + stopStoring.slice(12, 14);
        this.stopDate = new Date(stopStoring);
    }
        return [this.startDate, this.stopDate];
    }
     /**
      * ポップアップタイミングボックス入力時整数かチェック
      */
    popupTimingChange() {
        this.popupTimingMin = false;
        this.publicationSettingsService.popupTimingValid = false;
        if (this.popupTiming.value < 0) {
            this.popupTiming.value = 0;
            this.popupTimingMin = true;
            this.publicationSettingsService.popupTimingValid = true;
        }
        if (isNaN(this.popupTiming.value) === true) {
            this.popupTiming.value = 0;
            this.popupTimingMin = true;
            this.publicationSettingsService.popupTimingValid = true;
        }
        this.popupTiming.value = Math.floor(this.popupTiming.value);
    }
    /**
     * ポップアップインターバルボックス入力時整数かチェック
     */
    popupIntervalChange() {
        this.popupIntervalMin = false;
        this.publicationSettingsService.popupIntervalValid = false;
        if (this.popupInterval.value < 1) {
            this.popupInterval.value = 1;
            this.popupIntervalMin = true;
            this.publicationSettingsService.popupIntervalValid = true;
        }
        if (isNaN(this.popupInterval.value) === true) {
            this.popupInterval.value = 1;
            this.popupIntervalMin = true;
            this.publicationSettingsService.popupIntervalValid = true;
        }
        this.popupInterval.value = Math.floor(this.popupInterval.value);
    }

    makeSelectOptions(start: number, end: number, term: number, label: string) {
        const rtnVal = [];

        for (let i = start; i <= end; i = i + term) {
            rtnVal.push({
                label: i + label,
                value: i
            });
        }

        return rtnVal;
    }
}
