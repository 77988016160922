import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { Shop } from './shop';
import { Observable, of } from 'rxjs';
import { ShopInfoUpdateReqData } from './shopInfoUpdateReqData';
import { ResponseData } from '../response-data';
import { SessionService } from './../session.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class ShopService {

    constructor(private http: HttpClient, private sessionService: SessionService) { }

    /**
     * 画面に表示するショップ情報を取得する
     * @returns Observable<Shop> ショップ情報
     */
    getShopInfo(): Observable<Shop> {
        return this.http.get<Shop>(this.sessionService.getShopUrl()).pipe(
            catchError(this.handleError<Shop>('getShopInfo'))
        );
    }

    /**
     * 画面に入力した内容でショップ情報を更新する
     * @param ShopInfoUpdateReqData 更新対象のショップ情報
     * @returns Observable<ResponseData> レスポンスデータ
     */
    updateShopName(shopInfoUpdateReqData: ShopInfoUpdateReqData): Observable<ResponseData> {
        return this.http.put<ResponseData>(this.sessionService.getShopUrl(), shopInfoUpdateReqData, httpOptions).pipe(
            catchError(this.handleError<ResponseData>('updateShopName'))
        );
    }

    /**
     * エラーハンドラー
     * @param operation, result
     * @returns Observable<T>
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }
}
