import { Component, OnInit } from '@angular/core';
import { ValueShereService } from '../value-shere.service';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {

  constructor(private valueShareService: ValueShereService) { }

  ngOnInit() {
    this.valueShareService.isError = true;
  }
}
