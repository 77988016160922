export class AdInfo {
    shopId: number;
    adTitle: string;
    popupTargetSegmentOption: number;
    appealPageOption: number;
    appealPageUrl: string;
    appealPageUrlTarget: boolean;
    viewConversionFlag: boolean;
    popupTargetPageOption: number;
    popupTargetPageUrl: string;
    adBudgetOption: number;
    adBudget: number;
    pictureId: string;
    pictureCreateOption: number;
    pictureText: string;
    pictureData: string;
    pictureDataBig: any;
    popupDeviceOption: number;
    popupTiming: number;
    popupPeriodOption: number;
    publicationStartDate: string;
    publicationStopDate: string;
    popupIntervalOption: number;
    popupInterval: number;
    popupIntervalDays: number;
    popupIntervalHours: number;
    popupIntervalMinutes: number;
    templateId: number;
}
