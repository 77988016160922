var ocnkPopupProtocol = location.protocol;
var popupService = {
    serverDomain: ocnkPopupProtocol + '//www.ocnk-popup.net',
    popup_shop_key: '8585d026987b7e60a8c43038ce286eede37bd6789e6b23101a4eb171a80a1893'
};

function addOcnkPopupScript() {
    var ocnkPopupScript = document.createElement('script');
    ocnkPopupScript.type = "text/javascript";
    ocnkPopupScript.src = popupService.serverDomain + '/api/js/popup.min.js';
    ocnkPopupScript.async = true;
    document.head.appendChild(ocnkPopupScript);
    
    var ocnkPopupStyle = document.createElement('link');
    ocnkPopupStyle.type = 'text/css';
    ocnkPopupStyle.rel = 'stylesheet';
    ocnkPopupStyle.href = popupService.serverDomain + '/api/css/popup.css';;
    document.head.appendChild(ocnkPopupStyle);
}