import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';

const TOKEN_KEY = 'accessToken';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    @Inject(SESSION_STORAGE)
    private storage: WebStorageService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // '/api'から始まるリクエスト
    if (request.url.match(/^api\//) || request.url.match(/^\/api\//)) {
      const req = request.clone({
        url: `${request.url}`,
        withCredentials: false,
        setHeaders: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': 'Bearer ' + this.storage.get(TOKEN_KEY),
        },
      });
      return next.handle(req);
    }
    return next.handle(request);
  }
}
