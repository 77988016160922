export class ConstText {
    // ラジオボタンで用いる定数
    static q1Data = [
        { label: 'すべての人', value: '1' },
        { label: '初めての人', value: '2' },
        { label: '興味のある人', value: '3' },
        { label: '買い物しそうな人', value: '4' },
    ];

    static q2Data = [
        {
            label: '商品',
            value: '1',
            text: '商品ページのURLを入力してください。',
        },
        {
            label: 'クーポン・独自のキャンペーン',
            value: '2',
            text: 'クーポン・キャンペーンのURLを入力してください。',
        },
    ];

    static q3Data = [{ label: 'すべてのページ', value: '1' }, { label: '自分でURLを指定する', value: '2' }];

    static q3DataUrl = [{ label: 'すべてのページ', value: '1' }];

    static q4Data = [
        { label: '1日500円まで(1日で最大2500回表示できます)', value: '1' },
        { label: '1日1000円まで(1日で最大5000回表示できます)', value: '2' },
        { label: '1日5000円まで(1日で最大25000回表示できます)', value: '3' },
    ];

    static q5Data = [
        { label: 'テキストと背景を選んで作る(おすすめ)', value: '1' },
        { label: '自分で作った画像を投稿する(慣れている方向け)', value: '2' },
    ];

    static q6Data = [{ label: 'PCサイトのみ', value: '1' }, { label: 'スマホサイトのみ', value: '2' }, { label: '両方', value: '3' }];

    static q7Data = [{ label: '今すぐ', value: '1' }, { label: '期間を設定', value: '2' }];

    static q8Data = [{ label: '毎回出す', value: '1' }, { label: '一回出したら', value: '2' }];

    // カレンダーで用いる定数
    static ja = {
        firstDayOfWeek: 0,
        dayNames: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
        dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    };

    // canvasのテキストフォントで用いる定数
    static pictureTextSizes = [
        { id: 0, size: 10 },
        { id: 1, size: 12 },
        { id: 2, size: 14 },
        { id: 3, size: 16 },
        { id: 4, size: 18 },
        { id: 5, size: 20 },
        { id: 6, size: 30 },
        { id: 7, size: 40 },
        { id: 8, size: 50 },
        { id: 9, size: 60 },
        { id: 10, size: 70 },
        { id: 11, size: 80 },
    ];
    static pictureTextFonts = [
        { id: 0, font: '游ゴシック', fontName: 'Yu Gothic' },
        { id: 1, font: '游明朝', fontName: 'Yu Mincho' },
        {
            id: 2,
            font: 'ヒラギノ角ゴ',
            fontName: 'Hiragino Kaku Gothic Pro',
        },
        { id: 3, font: 'ヒラギノ明朝', fontName: 'Hiragino Mincho Pro' },
        { id: 4, font: 'M PLUS 1p', fontName: 'M PLUS 1p' },
        { id: 5, font: 'はんなり明朝', fontName: 'Hannari' },
        { id: 6, font: 'こころ明朝', fontName: 'Kokoro' },
        { id: 7, font: 'さわらび明朝', fontName: 'Sawarabi Mincho' },
        { id: 8, font: 'さわらびゴシック', fontName: 'Sawarabi Gothic' },
        { id: 9, font: 'ニクキュウ', fontName: 'Nikukyu' },
        { id: 10, font: 'ニコモジ', fontName: 'Nico Moji' },
        { id: 11, font: 'Arial', fontName: 'arial' },
        { id: 12, font: 'Arial Black', fontName: 'arial black' },
        { id: 13, font: 'Helvetica', fontName: 'Helvetica' },
        { id: 14, font: 'Times New roman', fontName: 'Times New Roman' },
        { id: 15, font: 'Comic Sans MS', fontName: 'Comic Sans MS' },
        { id: 16, font: 'Georgia', fontName: 'Georgia' },
        { id: 17, font: 'Impact', fontName: 'Impact' },
        { id: 18, font: 'Verdana', fontName: 'Verdana' },
    ];
    static pictureTextStyles = [
        { id: 0, style: '標準', styleName: 'normal' },
        { id: 1, style: '太字', styleName: 'bold' },
        { id: 2, style: '斜体', styleName: 'oblique' },
    ];

    static canvasParam = {
        width: 600,
        height: 374,
    };


}
