import {
    Component,
    OnInit,
    ViewChild,
    ViewContainerRef,
    AfterViewInit,
    OnDestroy,
} from '@angular/core';
import { PreviewBgFrameService } from './preview-bg-frame.service';
import { Subscription } from 'rxjs';
import { PublicationSettingsService } from '../publication-settings.service';

@Component({
    selector: 'app-preview-bg-frame',
    templateUrl: './preview-bg-frame.component.html',
    styleUrls: ['./preview-bg-frame.component.scss', '../publication-settings.component.scss'],
})
export class PreviewBgFrameComponent
    implements OnInit, AfterViewInit, OnDestroy {
    // 表示先divのDOM情報取得
    @ViewChild('inner', { read: ViewContainerRef, static: true }) vcr;

    // モーダルポップアップ表示用
    private subscription: Subscription;
    public display = 'none';


    constructor(private preview: PreviewBgFrameService, private publicationSettingsService: PublicationSettingsService) {}

    ngAfterViewInit() {
        // 取得した情報を送る
        this.preview.vcr = this.vcr;
    }

    ngOnInit() {
        // モーダル作成
        this.subscription = this.preview.content$.subscribe(value => {
            if (value) {
                this.display = '';
            } else {
                this.display = 'none';
            }
        });
    }

    /** ポップアップクリック時のクリックイベントの伝播防止
     * $event クリックイベント
     */
    containerClick($event) {
        $event.stopPropagation();
    }

    /** モーダルポップアップを閉じる */
    close() {
        this.publicationSettingsService.previewHidden = false;
        this.preview.close();
    }

    /** モーダルポップアップを閉じた時subscriptionを止める */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
