import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { AdInfo } from './ad-info';
import { PicTemp } from './pic-temp';
import { Observable, of } from 'rxjs';
import { Const } from '../const';
import { ResponseData } from '../response-data';
import { SessionService } from '../session.service';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})

export class PublicationSettingsService {

    constructor(private http: HttpClient, private sessionService: SessionService) {}

    // サイドバーからの出稿画面への遷移リンク非活性化用
    public openPublicationSettings = false;

    // adInfoの初回ロード確認用
    public firstGetAdInfo = false;

    // プレビュー画面にURLを送る用
    public targetUrl: string;
    public appealUrl: string;

    // プレビュー画面に画像データを送る用
    public canvas300: string;
    public canvasOrigin: string;
    public upload300: string;
    public uploadOrigin: any;

    // 出稿対象の情報をダッシュボードから取得する用
    public targetUser: number;

    // ポップアップ名の長さ検知用
    public adTitleMaxLength = false;

    // Q3のバリデーション用
    public popupTargetPageUrlValid = false;

    // Q5の表示切り替え用
    public pictureCreateHide = false;

    // ポップアップタイミングバリデーション用
    public popupTimingValid = false;

    // Q8のバリデーション用
    public popupIntervalValid = false;

    // バナー画像の有無チェック用
    public pictureUpFlag = false;

    // プレビューの表示タイミング用
    public previewHidden = false;

    // 新しいタブで開くフラグ
    public appealPageUrlTarget = false;

    // コンバージョン表示フラグ
    public viewConversionFlag = false;

    public recommendText = {
        top: 0,
        left: 0
    };
    /**
     * 広告IDに該当する広告情報を取得する
     * @param number 広告ID
     * @returns Observable<AdInfo>
     */
    getAdInfo(adId: string): Observable<AdInfo> {
        return this.http.get<AdInfo>(this.sessionService.getAdInfoUrl(adId));
    }

    /**
     * テンプレート画像情報を取得する
     * @returns Observable<PicTemp>
     */
    getPicTemp(): Observable<PicTemp> {
        return this.http.get<PicTemp>(Const.pictureUrl);
    }

    /**
     * 画面で入力した内容で広告データを作成する
     * @param AdInfo 出稿設定画面で入力した広告データ
     * @returns void
     */
    createAdInfo(adInfo: AdInfo): Observable<ResponseData>  {
        return this.http.post<ResponseData>(this.sessionService.getCreateAdInfoUrl(), adInfo, httpOptions).pipe(
            catchError(this.handleError<ResponseData>('createAdInfo'))
        );
    }

    /**
     * 画面で入力した内容で広告データを更新する
     * @param AdInfo 出稿設定画面で入力した広告データ
     * @returns void
     */
    updateAdInfo(adInfo: AdInfo, adId: string): Observable<ResponseData>  {
        return this.http.put<ResponseData>(this.sessionService.getUpdateAdInfoUrl(adId), adInfo, httpOptions).pipe(
            catchError(this.handleError<ResponseData>('updateAdInfo'))
        );
    }

    /** エラー検知 */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }
}
