import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PublicationSettingsService } from '../../publication-settings.service';
import { AdInfo } from '../../ad-info';
import { PicTemp } from '../../pic-temp';
import { ConstText } from '../../const-text';
import * as WebFont from 'webfontloader'; // webフォント利用ライブラリ
import * as jimp from 'jimp'; // 画像処理ライブラリ
import { stringify } from 'querystring';

// ドロップダウン用
interface TextSize {
    id: number;
    size: number;
}
interface TextFont {
    id: number;
    font: string;
    fontName: string;
}
interface TextStyle {
    id: number;
    style: string;
    styleName: string;
}

@Component({
    selector: 'app-canvas-settings',
    templateUrl: './canvas-settings.component.html',
    styleUrls: ['./canvas-settings.component.scss', '../simple-settings.component.scss', '../../publication-settings.component.scss'],
})
export class CanvasSettingsComponent implements OnInit {
    // publication-settingsのコンポーネントで定義した変数
    @Input() adInfo: AdInfo;

    // バナー画像のドラッグ用divタグのDOM要素取得
    @ViewChildren('divTextLength', { read: ElementRef }) divTextLength: QueryList<ElementRef>;

    // canvasに必要なDOM要素の取得
    @ViewChild('myCanvas', { static: false }) myCanvas;
    @ViewChild('picturePreview', { static: false }) picturePreview: ElementRef;

    // 取得したcanvas関連の値用
    picTemp = new PicTemp();
    pictures: any[] = [];
    picturePath: string;
    getPictures: any[] = [];

    // ギャラリー用
    galleryImgOpacity: number[] = [1, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2];
    imgSelect = 0;
    showImg = 0;


    // canvasの基本的な値用
    add = -1;
    canvasParam = ConstText.canvasParam;
    pictureTextSizes: TextSize[] = ConstText.pictureTextSizes;
    pictureTextFonts: TextFont[] = ConstText.pictureTextFonts;
    pictureTextStyles: TextStyle[] = ConstText.pictureTextStyles;
    pictureTextLeft: number[] = [];
    pictureTextTop: number[] = [];
    pictureTextSize: number[] = [];
    pictureTexts: any[] = [
        {
            id: 0,
            text: 'テキスト',
            size: { id: 5, size: 20 },
            font: { id: 0, font: '游ゴシック', fontName: 'YuGothic' },
            style: { id: 0, style: '標準', styleName: 'normal' },
            left: 115,
            top: 81,
            divLeft: 20,
            divTop: 100,
            pastSize: { id: 5, size: 20 },
            color: '#000000',
            colorCheck: false,
            backColor: '#f5f5f5',
        },
    ];
    context: CanvasRenderingContext2D;
    font: any;
    canvasPopupOrigin: any = './../../../../assets/images/sample/loading.png';
    canvasPopup300: any = './../../../../assets/images/sample/loading.png';
    canvasPicture: any = './../../../../assets/images/sample/loading.png';
    selectText: boolean[] = [];
    divColor: string[] = ['#f5f5f5', '#eeeeee'];
    moveStartX: number;
    moveStartY: number;
    moveText: number;

    // カラーコードバリデーション用
    colorPatern = '#([0-9a-fA-F]{6})$';
    colorValidError = '#000000';
    borderColor: string[] = ['#a6a6a6'];

    // フラグ管理用
    colorValidResult = false;
    textAddAble = false;
    sizeError = false;
    moveFlag = false;
    pictureHideFlag = [true, true, true, true, true, true, true, true];

    constructor(private publicationSettingsService: PublicationSettingsService, private fb: FormBuilder) {}

    ngOnInit() {
        for (let loop = 0; loop <= 7; loop++) {
            this.pictures.push({ source: './../../../../assets/images/sample/loading.png', title: 'loading' });
        }
    }

    /**
     * adInfo取得時に発火
     */
    ngOnChanges(): void {
        // @Inputの仕様で起きるadInfoに値が入る前の発火を阻止する
        if (this.adInfo !== null && this.publicationSettingsService.firstGetAdInfo === true) {
            this.publicationSettingsService.getPicTemp().subscribe(res => {
                // canvasテキスト用の配列を生成
                if (this.adInfo.pictureText) {
                    this.pictureTexts = JSON.parse(this.adInfo.pictureText);
                }
                // 空白のテキストを削除
                for (let loop = this.pictureTexts.length - 1; loop >= 0; loop--) {
                    if (this.pictureTexts[loop]['text'] === '') {
                        this.pictureTexts.splice(loop, 1);
                    }
                }
                for (let loop = 0; loop <= this.pictureTexts.length - 1; loop++) {
                    this.add = this.add + 1;
                    // テキスト初期位置設定left
                    this.pictureTexts[loop].divLeft = this.pictureTexts[loop].left;
                    // テキスト初期位置設定top
                    this.pictureTexts[loop].divTop = this.pictureTexts[loop].top;
                }
                this.picTemp = res;
                // ギャラリー用に配列を生成
                for (let loop = 0; loop <= Object.keys(this.picTemp).length - 1; loop++) {
                    this.getPictures.push({ source: this.picTemp[loop].image, title: this.picTemp[loop]['templateId'] });
                }
                // サンプル画像がギャラリーの表示数未満の場合の対策(非表示対応)
                if (Object.keys(this.picTemp).length - 1 < 7) {
                    for (let loop = Object.keys(this.picTemp).length; loop <= 7; loop++) {
                        this.getPictures.push({ source: './../../../../assets/images/sample/loading.png', title: 'Nothing' });
                        this.pictureHideFlag[loop] = false;
                    }
                }
                // 取得した画像を格納
                this.pictures = this.getPictures;
                for (let loop = this.pictureTexts.length - 1; loop >= 0; loop--) {
                    if (this.pictureTexts[loop]['text'] === '') {
                        this.pictureTexts.splice(loop, 1);
                    }
                }
                // キャンバスの背景を設定
                for (let loop = 0; loop <= this.pictures.length - 1; loop++) {
                    if (Number(this.adInfo.templateId) === this.pictures[loop]['title']) {
                        this.canvasPicture = this.pictures[loop]['source'];
                        if (Object.keys(this.picTemp).length - 1 < 7) {
                            // サンプル画像がギャラリーの表示数未満の場合の対策(全ての画像を見せるよう調整)
                            // 左端の画像を選択
                            this.showImg = 0;
                            this.imgSelect = loop;
                            // 選択画像の強調
                            this.imgOpacity(8);
                        } else if (loop > Object.keys(this.picTemp).length - 8) {
                            // 選択されている画像が最終ページの画像の場合の対策(最終ページを表示するよう調整)
                            this.showImg = Object.keys(this.picTemp).length - 8;
                            this.imgSelect = loop - (Object.keys(this.picTemp).length - 8);
                            // 選択画像の強調
                            this.imgOpacity(8);
                        } else {
                            // 左端の画像を選択
                            this.showImg = loop;
                        }
                        // 画像変更反映の完了時にdraw()の呼び出し
                        const img = new Image();
                        img.src = this.canvasPicture;
                        img.onload = () => {
                            this.draw();
                        };
                    }
                }
                // canvasのDOM要素を取得
                const canvas = this.myCanvas.nativeElement;
                // canvas描画メソッドを注入
                this.context = canvas.getContext('2d');
                // テキストフォントをロード
                this.fontLoad();
                // 最新の値を取得できるようにフラグをfalseに変更
                this.publicationSettingsService.firstGetAdInfo = false;
            });
        }
    }

    /**
     * WebFontLoaderライブラリでGoogleFontをインポート
     */
    fontLoad() {
        WebFont.load({
            custom: {
                families: ['Mplus 1p', 'Hannari', 'Kokoro', 'Sawarabi Mincho', 'Sawarabi Gothic', 'Nikukyu', 'Nico Moji'],
                urls: [
                    'https://fonts.googleapis.com/earlyaccess/mplus1p.css',
                    'https://fonts.googleapis.com/earlyaccess/hannari.css',
                    'https://fonts.googleapis.com/earlyaccess/kokoro.css',
                    'https://fonts.googleapis.com/earlyaccess/sawarabimincho.css',
                    'https://fonts.googleapis.com/earlyaccess/sawarabigothic.css',
                    'https://fonts.googleapis.com/earlyaccess/nikukyu.css',
                    'https://fonts.googleapis.com/earlyaccess/nicomoji.css',
                ],
            },
            timeout: 3000,
            // ロードしているとき allfonts
            loading: () => {},
            // Web Fontが使用可能になったとき allfonts
            active: () => {},
            // ブラウザがサポートしていないとき allfonts
            inactive: () => {},
            // fontFamilyをロードしているとき onefont
            fontloading: (fontFamily, fontDescription) => {},
            // fontFamilyが使用可能になったとき onefont
            fontactive: (fontFamily, fontDescription) => {},
            // fontFamilyをブラウザがサポートしていないとき onefont
            fontinactive: (fontFamily, fontDescription) => {},
        });
    }

    /**
     * ngFor繰り返しの際の繰り返し回数の取得
     * @param index 繰り返し回数
     * @param obj 現在利用されているオブジェクトの内容
     */
    myTrackBy(index: number, obj: any): any {
        return index;
    }

    /**
     * バナーサンプルに乗せるテキストの追加
     */
    addPictureText() {
        this.add = this.add + 1;
        this.pictureTexts.push({
            id: this.add,
            text: 'テキスト',
            size: { id: 5, size: 20 },
            font: { id: 0, font: '游ゴシック', fontName: 'YuGothic' },
            style: { id: 0, style: '標準', styleName: 'normal' },
            left: 115,
            top: 81,
            divLeft: 20,
            divTop: 100,
            pastSize: { id: 5, size: 20 },
            color: '#000000',
            colorCheck: false,
            backColor: '#f5f5f5',
        });
        for (let loop = 0; loop <= this.add; loop++) {
            this.pictureTexts[loop]['id'] = loop;
            // テキスト編集フォームの背景色を互い違いにする
            this.pictureTexts[loop]['backColor'] = this.divColor[loop % 2];
        }
        // テキストサイズを保持するための宣言
        this.pictureTexts[this.add].pastSize = this.pictureTexts[this.add].size;
        // formが10個の時テキスト追加ボタンを非活性にする
        if (this.add === 9) {
            this.textAddAble = true;
        }
        this.draw();
    }

    /**
     * canvas描画
     */
    draw() {
        if (this.publicationSettingsService.pictureCreateHide === false) {
            const ctx = this.context;
            if (ctx) {
                // テキストの基本値を指定
                ctx.textAlign = 'left';
                ctx.textBaseline = 'top';
                // canvasをクリア
                ctx.clearRect(0, 0, this.canvasParam['width'], this.canvasParam['height']);
                // canvasに画像を描画
                ctx.drawImage(this.picturePreview.nativeElement, 0, 0, this.canvasParam['width'], this.canvasParam['height']);
                // OSを判別
                if (navigator.platform.indexOf('Win') !== -1) {
                    for (let loop = 0; loop <= this.add; loop++) {
                        if (this.pictureTexts[loop]['font']['id'] === 0) {
                            this.pictureTexts[loop]['font']['fontName'] = 'Yu Gothic';
                        }
                        if (this.pictureTexts[loop]['font']['id'] === 1) {
                            this.pictureTexts[loop]['font']['fontName'] = 'Yu Mincho';
                        }
                    }
                } else {
                    for (let loop = 0; loop <= this.add; loop++) {
                        if (this.pictureTexts[loop]['font']['id'] === 0) {
                            this.pictureTexts[loop]['font']['fontName'] = 'YuGothic';
                        }
                        if (this.pictureTexts[loop]['font']['id'] === 1) {
                            this.pictureTexts[loop]['font']['fontName'] = 'YuMincho';
                        }
                    }
                }
                for (let loop = 0; loop <= this.add; loop++) {
                    // canvasに描画するテキストのフォント指定用の変数を作成
                    (this.font =
                        this.pictureTexts[loop]['style']['styleName'] +
                        ' ' +
                        this.pictureTexts[loop]['size']['size'] * 2 +
                        'px \'' +
                        this.pictureTexts[loop]['font']['fontName'] +
                        '\''),
                        (ctx.font = this.font);
                    if (this.pictureTexts[loop].color.match(this.colorPatern) === null) {
                        ctx.fillStyle = this.colorValidError;
                        this.pictureTexts[loop].color = '#000000';
                    } else {
                        ctx.fillStyle = this.pictureTexts[loop].color;
                    }
                    // canvasにテキストを描画
                    ctx.fillText(this.pictureTexts[loop].text, this.pictureTexts[loop].left * 2, this.pictureTexts[loop].top * 2);
                }
            }
            // 各種テキスト用の値をJSON型化
            this.adInfo.pictureText = JSON.stringify(this.pictureTexts);
            // 再度canvasのDOM要素を取得
            const canvas = this.myCanvas.nativeElement;
            // canvas内の描画情報を.pngとして出力
            this.canvasPopupOrigin = canvas.toDataURL('image/png');
            // 出力画像の送信
            this.adInfo.pictureDataBig = this.canvasPopupOrigin;
            // プレビューボタンで表示させるために入れる
            this.publicationSettingsService.canvasOrigin = this.canvasPopupOrigin;
            // Jimpライブラリを用いて画像編集
            jimp.read(this.canvasPopupOrigin).then(img => {
                // 300pxにリサイズ
                img.resize(300, jimp.AUTO);
                // 300pxの送信
                this.canvasPopup300 = img.getBase64Async(jimp.MIME_PNG);
                this.canvasPopup300 = this.canvasPopup300.__zone_symbol__value;
                this.adInfo.pictureData = this.canvasPopup300;
                this.publicationSettingsService.canvas300 = this.canvasPopup300;
                // 画像がない場合ボタンを非活性化するためのフラグ
                this.publicationSettingsService.pictureUpFlag = false;
            });
        }
    }

    /**
     * バナー画像のテキストカラーのバリデーションチェック
     * チェックタイミング：カラーテキストボックスの値が変わったらチェックが走る
     * @param i 編集されたのはどれか
     */
    colorValid(i: number) {
        // エラー表示フラグ
        this.colorValidResult = false;
        if (this.pictureTexts[i].color.match(this.colorPatern) === null) {
            this.pictureTexts[i].colorCheck = true;
            this.colorValidResult = true;
            this.borderColor[i] = '#910000';
        } else {
            this.pictureTexts[i].colorCheck = false;
            this.borderColor[i] = '#a6a6a6';
        }
        this.draw();
    }

    /**
     * ギャラリーの画像クリック時のcanvas反映
     * @param i クリックされた画像の番号
     */
    imgClick(i: number) {
        this.imgSelect = i;
        // 選択画像の強調
        this.imgOpacity(8);
        // 画像反映
        this.canvasPicture = this.pictures[this.showImg + this.imgSelect]['source'];
        // 画像のテンプレートIDを送信
        this.adInfo.templateId = this.pictures[this.showImg + this.imgSelect]['title'];
        // 画像変更反映の完了時にdraw()の呼び出し
        const img = new Image();
        img.src = this.canvasPicture;
        img.onload = () => {
            this.draw();
        };
    }

    /**
     * ギャラリーの画像送り時のcanvas反映
     * @param i 選択された画像の番号
     */
    imgMove(i: number) {
        // 画像総数に合わせてギャラリーを変更
        // 表示させる画像の最大数
        let loopMax: number;
        // ループせずページ送りを行う最大数(画像総数 - loopMax)
        let pageMax: number;
        // 画像ページ変更のタイミング設定
        if (Object.keys(this.picTemp).length - 1 < 7) {
            // サンプル画像がギャラリーの表示数未満の場合の対策(画像送りの最大値を調整)
            loopMax = Object.keys(this.picTemp).length - 1;
        } else {
            loopMax = 7;
        }
        // 画像ページの最大値設定
        if (Object.keys(this.picTemp).length - 8 <= 0) {
            // サンプル画像がギャラリーの表示数未満の場合の対策(画像ページ変更を無効化)
            pageMax = 0;
        } else {
            pageMax = Object.keys(this.picTemp).length - 8;
        }
        // 画像左送り
        if (i === 0) {
            // ページ送りをするか判断（選択中の画像が左端か）
            if (this.imgSelect !== 0) {
                // 選択画像の変更
                this.imgSelect = this.imgSelect - 1;
                // 選択画像の強調
                this.imgOpacity(loopMax);
            } else if (this.showImg !== 0) {
                // 左にページ送り
                this.showImg = this.showImg - 1;
            } else if (this.showImg === 0) {
                // ページが最小値のため最大値のページに送る(ループさせる)
                this.showImg = pageMax;
                this.imgSelect = loopMax;
                // 選択画像の強調
                this.imgOpacity(loopMax);
            }
        }
        // 画像右送り
        if (i === 1) {
            // ページ送りをするか判断（選択中の画像が右端か）
            if (this.imgSelect !== loopMax) {
                // 選択画像の変更
                this.imgSelect = this.imgSelect + 1;
                // 選択画像の強調
                this.imgOpacity(loopMax);
            } else if (this.showImg !== pageMax) {
                // 右にページ送り
                this.showImg = this.showImg + 1;
            } else if (this.showImg === pageMax) {
                // ページが最大値のため最小値のページに送る(ループさせる)
                this.showImg = 0;
                this.imgSelect = 0;
                // 選択画像の強調
                this.imgOpacity(loopMax);
            }
        }
        // 画像反映
        this.canvasPicture = this.pictures[this.showImg + this.imgSelect]['source'];
        // 画像のテンプレートIDを送信
        this.adInfo.templateId = this.pictures[this.showImg + this.imgSelect]['title'];
        // 画像変更反映の完了時にdraw()の呼び出し
        const img = new Image();
        img.src = this.canvasPicture;
        img.onload = () => {
            this.draw();
        };
    }

    imgOpacity(totalImgNum: number) {
        for (let loop = 0; loop <= totalImgNum; loop++) {
            if (loop === this.imgSelect) {
                this.galleryImgOpacity[loop] = 1.0;
            } else {
                this.galleryImgOpacity[loop] = 0.2;
            }
        }
    }

    /**
     * バナー画像のテキストのサイズ制限
     * @param i どのテキストが選択されているか
     */
    sizeChange(i: number) {
        this.sizeError = false;
        // テキストサイズが一定値を超えていないか確認
        this.divTextLength.forEach(child => {
            if (child.nativeElement.offsetWidth > 280) {
                this.pictureTexts[i]['size'] = this.pictureTexts[i]['pastSize'];
                this.sizeError = true;
            }
        });
        this.pictureTexts[i]['pastSize'] = this.pictureTexts[i]['size'];
        this.draw();
    }

    /**
     * 選択されたバナー画像に乗せるテキストの削除
     */
    pictureTextTrash() {
        for (let loop = 9; loop >= 0; loop--) {
            if (this.selectText[loop] === true) {
                this.pictureTexts.splice(loop, 1);
                this.add = this.add - 1;
                this.selectText[loop] = false;
            }
        }
        // テキスト編集フォームの背景色を互い違いにする
        for (let loop = 0; loop <= this.add; loop++) {
            this.pictureTexts[loop]['backColor'] = this.divColor[loop % 2];
        }
        if (this.add < 9) {
            this.textAddAble = false;
        }
        this.draw();
    }

    /**
     * バナー画像のテキスト移動
     * release後対応
     * @param axis x方向移動(0)かy方向移動(1)か確認
     * @param moveDistance 移動距離
     */
    pictureTextMove(axis: number, moveDistance: number) {
        for (let loop = 0; loop <= this.add; loop++) {
            if (this.selectText[loop] === true && axis === 0) {
                this.pictureTexts[loop]['left'] = this.pictureTexts[loop]['left'] + moveDistance;
            }
            if (this.selectText[loop] === true && axis === 1) {
                this.pictureTexts[loop]['top'] = this.pictureTexts[loop]['top'] + moveDistance;
            }
            this.divMoveValid(loop);
        }
        this.draw();
    }

    /**
     * バナー画像のテキスト移動の開始
     * @param event クリック開始時のマウス情報
     * @param i どのテキストが選択されているか
     */
    divDragStart(event, i: number) {
        this.moveText = i;
        this.moveStartX = event.screenX;
        this.moveStartY = event.screenY;
        this.moveFlag = true;
    }
    /**
     * バナー画像のテキスト移動のcanvasへの反映
     * @param event クリック終了時のマウス情報
     */
    divDragEnd() {
        this.divMoveValid(this.moveText);
        this.moveFlag = false;
        this.draw();
    }
    /**
     * バナー画像のdivドラッグ情報の取得
     * @param event クリック終了時のマウス情報
     */
    divDrag(event) {
        if (this.moveFlag === true) {
            this.pictureTexts[this.moveText].left = this.pictureTexts[this.moveText].left + (event.screenX - this.moveStartX);
            this.pictureTexts[this.moveText].top = this.pictureTexts[this.moveText].top + (event.screenY - this.moveStartY);
            this.moveStartX = event.screenX;
            this.moveStartY = event.screenY;
        }
    }

    /**
     * divタグの移動制限
     * @param select 移動したdivタグ
     */
    divMoveValid(select: number) {
        if (this.pictureTexts[select].left > (this.canvasParam['width'] / 2) - 20) {
            this.pictureTexts[select].left = (this.canvasParam['width'] / 2) - 20;
        }
        if (this.pictureTexts[select].left < 0) {
            this.pictureTexts[select].left = 0;
        }
        if (this.pictureTexts[select].top > (this.canvasParam['height'] / 2) - 20) {
            this.pictureTexts[select].top = (this.canvasParam['height'] / 2) - 20;
        }
        if (this.pictureTexts[select].top < 0) {
            this.pictureTexts[select].top = 0;
        }
    }
}
