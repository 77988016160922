import { Component, OnInit } from '@angular/core';
import { ValueShereService } from '../value-shere.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private valueShareService: ValueShereService) { }

  ngOnInit() {
    this.valueShareService.isError = true;
  }
}
