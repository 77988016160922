import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export class Report {
    adId: number;
    displayOrder: number;
    originOrder: number;
    setOrderByUser: boolean;
    popupTargetSegmentOption: number;
    pictureData: string;
    pictureDataBig: string;
    adTitle: string;
    viewConversionFlag: boolean;
    popupPeriod: string;
    adCosts: number;
    impression: number;
    clickCount: number;
    clickRate: number;
    pcCnt: number;
    mobileCnt: number;
    pcUserClickCount: number;
    pcUserClickRate: string;
    phoneUserClickCount: number;
    phoneUserClickRate: string;
    testBigUrl: SafeUrl;
    stateFlag: number;
    publicationState: string;
    orderCnt: number;
    orderRate: number;
    orderAmt: number;
    orderPcCnt: number;
    orderPcRate: number;
    orderPcAmt: number;
    orderMobileCnt: number;
    orderMobileRate: number;
    orderMobileAmt: number;
}
