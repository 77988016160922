import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import {InputTextModule} from 'primeng/inputtext';

import { ReportService } from '../report/report.service';
import { Report } from '../report/report';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ResponseData } from '../response-data';
import {CalendarModule} from 'primeng/calendar';
import { ExcelService } from '../excel.service';
import { SessionService } from '../session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-landscape',
  templateUrl: './report-landscape.component.html',
  styleUrls: ['./report-landscape.component.scss']
})
export class ReportLandscapeComponent implements OnInit {
  @ViewChild('startCalendar', null) startCalendar;
  @ViewChild('endCalendar', null) endCalendar;
  constructor(
    private reportService: ReportService,
    private domSanitizer: DomSanitizer,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public changeDetectorRef: ChangeDetectorRef,
    private excelService: ExcelService,
    private sessionService: SessionService,
    private router: Router
  ) { }

  // calendar locale
  ja: any;
  // 検索開始日
  searchStartDate = '';

  // 検索終了日
  searchEndDate = '';
    // 出稿先ユーザーのラベル画像用
  labelSrc = [
      'assets/images/label-all/label-t.png',
      'assets/images/label-b/label-b.png',
      'assets/images/label-g/label-g.png',
      'assets/images/label-r/label-r.png'
  ];
  labelSrcSet = [
      'assets/images/label-all/label-t@2x.png 2x, assets/images/label-all/label-t@3x.png 3x',
      'assets/images/label-b/label-b@2x.png 2x, assets/images/label-b/label-b@3x.png 3x',
      'assets/images/label-g/label-g@2x.png 2x, assets/images/label-g/label-g@3x.png 3x',
      'assets/images/label-r/label-r@2x.png 2x, assets/images/label-r/label-r@3x.png 3x',
  ];

  // レポート情報用
  reports: Report[];
  adState = { stateFlag: 0 };

  reorderFlag = false;

  // レポートの存在チェック用
  nonReport = false;

  setByUserCnt = 0;

  toggleCalendar(kbn) {
    if (kbn === 'start') {
        this.startCalendar.showOverlay(this.startCalendar.nativeElement);
    } else if (kbn === 'end') {
        this.endCalendar.showOverlay(this.endCalendar.nativeElement);
    }
  }

  ngOnInit() {
    this.ja = {
        firstDayOfWeek: 0,
        dayNames: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
        dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthNamesShort: [ '1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月' ],
        today: '今日',
        clear: 'クリア',
        dateFormat: 'yy/mm/dd',
        weekHeader: 'Wk'
    };

    this.reportService.getReportType().subscribe(res => {
        if (res === '1') {
        this.router.navigate(['/report']);
        } else {
        this.getReportInfo();
        }
    });
  }

  /** reportServiceから取得した値をreportsに設定。 */
  getReportInfo(): void {
      this.reportService.getReportInfo(this.searchStartDate, this.searchEndDate).subscribe(res => {
          this.nonReport = false;
          this.reports = res;
          this.reports.forEach(report => {
              // report.pictureDataBig = this.domSanitizer.bypassSecurityTrustUrl(report.pictureDataBig);
              report.testBigUrl = this.domSanitizer.bypassSecurityTrustUrl(report.pictureDataBig + ' 2x');
              report.setOrderByUser = false;
              report.originOrder = report.displayOrder;
          });
          if ( this.reports.length === 0) {
              this.nonReport = true;
          }
      });
  }

  changeOrder(report: Report) {
      const fromNo = report.originOrder;
      const toNo = report.displayOrder;

      if (fromNo > toNo) {
        this.reports.map((item) => {
            if (item.originOrder >= toNo && item.originOrder < fromNo) {
                if (item.setOrderByUser !== true) {
                    item.displayOrder += this.setByUserCnt + 1;
                } else {
                    this.setByUserCnt++;
                }
            }

            return item;
        });
      } else {
        this.reports.map((item) => {
            if (item.originOrder > fromNo && item.originOrder <= toNo) {
                if (item.setOrderByUser !== true) {
                    item.displayOrder -= this.setByUserCnt + 1;
                } else {
                    this.setByUserCnt++;
                }
            }

            return item;
        });
      }

      this.reports.map((item) => {
          item.originOrder = item.displayOrder;
      });
  }

  sendReorder() {
    let sendReportData: Report[];

    // 無駄なデータを送らないため、転送変数を生成
    sendReportData = this.reports.map((item) => {
        const report = new Report();
        report.adId = item.adId;
        report.displayOrder = item.displayOrder;
        report.originOrder = item.originOrder;
        report.setOrderByUser = item.setOrderByUser;

        return report;
    });

    // ユーザが変更した並び順を優先してソートする。
    sendReportData.sort((origin, next) => {
        if (origin.displayOrder > next.displayOrder) {
            return 1;
        } else if (origin.displayOrder < next.displayOrder) {
            return -1;
        } else if (origin.displayOrder === next.displayOrder) {
            if (origin.setOrderByUser === next.setOrderByUser) {
                return 0;
            } else if (origin.setOrderByUser === true && next.setOrderByUser === false) {
                return -1;
            } else if (origin.setOrderByUser === false && next.setOrderByUser === true) {
                return 1;
            }
        }
    });

    // ソートしたINDEX順でサーバへ転送
    sendReportData.map((item, i) => {
        item.displayOrder = i + 1;

        return item;
    });

    this.confirmationService.confirm({
        message: 'レポートを並び替えしますか？',
        header: '並び替え確認',
        key: 'dialogConfirm',
        acceptLabel: 'はい',
        rejectLabel: 'いいえ',
        accept: () => {
            this.reportService.reportReorder(sendReportData).subscribe(res => {
                this.showMsg(res, {
                    successSummary: '並び替えました',
                    successDetail: '並び替えました。',
                    errorSummary: '並び替えエラー'
                });
            });
        },
        reject: () => {

        }
    });
  }

  donwloadExcel() {
    this.excelService.makeReportExcel(this.reports, this.sessionService.getShopName(), this.searchStartDate, this.searchEndDate);
  }

  /**
   * 出稿状態の変更を行う
   * adId 広告ID
   * state 出稿の停止情報
   */
  reportStop(adId: string, state: number, event) {
      this.adState.stateFlag = state;
      this.reportService.updateReportState(this.adState, adId).subscribe(() => {
          this.reInit();
      });
  }

  /**
   * 出稿内容のコピーを行う
   * adId 広告ID
   */
  reportCopy(adId: string) {
      this.confirmationService.confirm({
          message: '広告をコピーしますか？',
          header: 'コピー確認',
          key: 'dialogConfirm',
          acceptLabel: 'はい',
          rejectLabel: 'いいえ',
          accept: () => {
              this.reportService.copyReport(this.reports.find(i => i.adId === Number(adId)), adId).subscribe(res => {
                  this.showMsg(res, {
                      successSummary: 'コピーしました',
                      successDetail: 'コピーしました。',
                      errorSummary: 'コピーエラー'
                  });
              });
          },
          reject: () => {

          }
      });
 }

  /**
   * 各レポートAPIでの変更結果をメッセージで出力する
   * @event 変更ボタンクリック時
   * @returns void
   */
  showMsg(res: ResponseData, msgProperty: any) {
      this.messageService.clear();
      if (res.result === 'OK') {
          this.messageService.add({
              key: 'dialogReInit',
              sticky: false,
              severity: 'success',
              summary: msgProperty.successSummary,
              detail: msgProperty.successDetail,
              life: 2000
          });
      } else {
          this.messageService.add({
              key: 'dialogReInit',
              sticky: false,
              severity: 'error',
              summary: msgProperty.errorSummary,
              detail: res.resultMessage,
              life: 2000
          });
      }
      setTimeout(() => {
          this.messageService.clear('dialogReInit');
          this.reInit();
      }, 2000);
  }

  resultDelete(res: ResponseData) {
      this.messageService.clear('dialogReInit');
      if (res.result === 'OK') {
          this.messageService.add({
              key: 'dialogReInit',
              sticky: false,
              severity: 'success',
              summary: '削除完了',
              detail: '削除しました。',
              life: 2000
          });
      } else {
          this.messageService.add({
              key: 'dialogReInit',
              sticky: false,
              severity: 'error',
              summary: '削除エラー',
              detail: res.resultMessage,
              life: 2000
          });
      }
      setTimeout(() => {
          this.messageService.clear('dialogReInit');
          this.reInit();
      }, 2000);
  }

  reInit() {
      this.ngOnInit();
  }

  deleteReport(adId: string, adTitle: string) {
      this.confirmationService.confirm({
          message: '[' + adTitle + ']広告を削除しますか？',
          header: '削除確認',
          key: 'dialogConfirm',
          acceptLabel: 'はい',
          rejectLabel: 'いいえ',
          accept: () => {
              this.reportService.deleteReport(adId).subscribe(res => {
                  this.resultDelete(res);
              });
          },
          reject: () => {

          }
      });
  }

  changeReportType(reportType: string) {
      this.reportService.changeReportType(reportType);
  }
}
