import { Component, OnInit } from '@angular/core';
import { ShopService } from './shop.service';
import { ShopInfoUpdateReqData } from './shopInfoUpdateReqData';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ResponseData } from '../response-data';
import { SessionService } from '../session.service';

@Component({
    selector: 'app-basic-settings',
    templateUrl: './basic-settings.component.html',
    styleUrls: ['./basic-settings.component.scss']
})
export class BasicSettingsComponent implements OnInit {

    constructor(private shopService: ShopService, private fb: FormBuilder, private messageService: MessageService, private sessionService: SessionService) {  }

    // FormGroup宣言
    basicSettingsForm = this.fb.group({
        shopName: this.fb.control([, Validators.required, Validators.maxLength(30)]),
        scriptTag: this.fb.control([]),
        conversionTag: this.fb.control([]),
        ipAddresses: this.fb.array([])
    });

   // リクエストデータ
    shopInfoUpdateReqData = new ShopInfoUpdateReqData();

    // IPアドレス正規表現(IPv4)
    ipRegExp = '(([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])';

    ngOnInit() {
        this.getShopInfo();
        // 読み込み速度の関係でfunction~~と表示されるため、空白を代入しておく
        this.basicSettingsForm.patchValue({
          shopName: ''
        });
        // テキストエリアを非活性
        this.basicSettingsForm.controls.scriptTag.disable();
        this.basicSettingsForm.controls.conversionTag.disable();
    }

    /**
     * 基本設定画面読み込み時に画面項目を取得するメソッド
     * @returns void
     */
    getShopInfo(): void {
        this.shopService.getShopInfo().subscribe(res => {
            this.basicSettingsForm.patchValue({
                shopName: res.shopName,
                conversionTag: res.conversionTag,
                scriptTag: res.embedScript
            });
            // テキストボックスの個数を6に固定
            for (let i = 0; i < 6; i++) {
                if (res.blockIpAddrInfo) {
                    this.ipAddresses.push(this.fb.control(res.blockIpAddrInfo[i], Validators.pattern(this.ipRegExp)));
                } else {
                    this.ipAddresses.push(this.fb.control('', Validators.pattern(this.ipRegExp)));
                }
            }
        });
    }

    /**
     * 基本設定の変更を行う
     * @event 変更ボタンクリック時
     * @returns void
     */
    onClick(): void {
        this.shopInfoUpdateReqData.shopName = this.basicSettingsForm.value.shopName;

        // // 空の値を削除
        this.shopInfoUpdateReqData.blockIpAddrInfo = this.basicSettingsForm.value.ipAddresses
        .filter(word => word !== null && word.trim() !== '');
        this.shopService.updateShopName(this.shopInfoUpdateReqData).subscribe(res => this.showConfirm(res));
    }

    // フォームのgetter
    get ipAddresses() {
        return this.basicSettingsForm.get('ipAddresses') as FormArray;
    }
    get shopName() { return this.basicSettingsForm.get('shopName'); }
    get conversionTag() { return this.basicSettingsForm.get('conversionTag'); }

    /**
     * 基本設定の変更結果をメッセージで出力する
     * @event 変更ボタンクリック時
     * @returns void
     */
    showConfirm(res: ResponseData) {
        this.messageService.clear();
        if (res.result === 'OK') {
            this.messageService.add({key: 'ok', sticky: false, severity: 'success', summary: '変更しました', life: 2000} );
        } else {
            this.messageService.add({key: 'error', sticky: false, severity: 'error', summary: '設定できませんでした', life: 2000});
        }
    }

    /**
     * コンバージョンタグコピー時にダイアログを表示する
     * @event コピーボタンクリック時
     */
    copyClick() {
        this.messageService.clear();
        this.messageService.add({key: 'ok', sticky: false, severity: 'success', summary: 'コピーしました', life: 2000});
    }
}
