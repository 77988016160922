import { Component, OnInit } from '@angular/core';
import { ValueShereService } from '../value-shere.service';

@Component({
  selector: 'app-file-not-found',
  templateUrl: './file-not-found.component.html',
  styleUrls: ['./file-not-found.component.scss']
})
export class FileNotFoundComponent implements OnInit {

  constructor(private valueShareService: ValueShereService) { }

  ngOnInit() {
    this.valueShareService.isError = true;
  }

}
