import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { PublicationSettingsService } from '../publication-settings.service';
import { AdInfo } from '../ad-info';
import { ConstText } from '../const-text';
import { CanvasSettingsComponent } from './canvas-settings/canvas-settings.component';
import * as jimp from 'jimp';
@Component({
    selector: 'app-simple-settings',
    templateUrl: './simple-settings.component.html',
    styleUrls: ['./simple-settings.component.scss', '../publication-settings.component.scss'],
})
export class SimpleSettingsComponent implements OnInit, OnChanges {
    // 子コンポーネントを読み込む
    @ViewChild(CanvasSettingsComponent, { static: false })
    protected CanvasSettingsComponent: CanvasSettingsComponent;

    // publication-settingsのコンポーネントで定義した変数
    @Input() adInfo: AdInfo;

    // フォームの情報取得
    @Input('simpleSettingsForm') simpleSettingsForm: any;
    @Input('canvasSettingsForm') canvasSettingsForm: any;
    get adTitle() {
        return this.simpleSettingsForm.get('adTitle');
    }
    get popupTargetSegmentOption() {
        return this.simpleSettingsForm.get('popupTargetSegmentOption');
    }
    get appealPageOption() {
        return this.simpleSettingsForm.get('appealPageOption');
    }
    get appealPageUrl() {
        return this.simpleSettingsForm.get('appealPageUrl');
    }
    get popupTargetPageOption() {
        return this.simpleSettingsForm.get('popupTargetPageOption');
    }
    get popupTargetPageUrlArray() {
        return this.simpleSettingsForm.get('popupTargetPageUrlArray') as FormArray;
    }
    get adBudgetOption() {
        return this.simpleSettingsForm.get('adBudgetOption');
    }
    get adBudget() {
        return this.simpleSettingsForm.get('adBudget');
    }
    get pictureCreateOption() {
        return this.simpleSettingsForm.get('pictureCreateOption');
    }

    // ポップアップ名の文字数バリデーションチェックの結果を取得
    get adTitleMaxLength() {
        return this.publicationSettingsService.adTitleMaxLength;
    }

    // IPアドレス正規表現(URL)
    urlRegExp = '^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$';

    // 画像アップロード用
    uploadedFiles: any[] = [];
    pictureSrc = '';
    reader = new FileReader();

    // 画像リサイズ用
    resizePath: any;
    img300: string;
    imgOrigin: string;

    // アップロード画像プレビュー背景用
    divHeight = 211;

    // ラジオボタン用
    q1Data = ConstText.q1Data;
    q2Data = ConstText.q2Data;
    q3Data = ConstText.q3Data;
    q4Data = ConstText.q4Data;
    q5Data = ConstText.q5Data;

    // Q3のバリデーション用
    popupTargetPageUrlRequired = 6;

    // Q4の値同期用
    budget: number;
    budgetNum: number;

    // Q4のバリデーション用
    adBudgetInteger = false;
    adBudgetMin = false;

    constructor(public publicationSettingsService: PublicationSettingsService, private fb: FormBuilder) {}

    /**
     * adInfo取得時に発火
     */
    ngOnChanges(): void {
        // @Inputの仕様で起きるadInfoに値が入る前の発火を阻止する
        if (this.adInfo !== null && this.publicationSettingsService.firstGetAdInfo === true) {
            this.img300 = this.adInfo.pictureData;
            this.imgOrigin = this.adInfo.pictureDataBig;
            if (this.adInfo.pictureData) {
                jimp.read(this.adInfo.pictureData).then(img => {
                    this.divHeight = img.getHeight() + 24;
                });
            }
        }
    }

    ngOnInit() {}

    /**
     * ポップアップ名の文字数バリデーション
     * @param event 入力されたキー情報
     */
    adTitleKeyUp(event: any) {
        // backspaceキーの入力を検知
        if (event.keyCode === 8) {
            if (this.adTitle.value.length > 30) {
                this.publicationSettingsService.adTitleMaxLength = true;
            } else {
                this.publicationSettingsService.adTitleMaxLength = false;
            }
        }
    }

    /**
     *  URL入力の変更をサービスに送る
     */
    appealPageUrlChange() {
        this.publicationSettingsService.appealUrl = this.appealPageUrl.value;
        // this.publicationSettingsService.targetUrl = this.popupTargetPageUrlArray.value; iframe実装見送りのためコメントアウト
    }

    /**
     * Q3のフォームバリデーション
     */
    popupTargetPageUrlKeyDown() {
        this.publicationSettingsService.popupTargetPageUrlValid = false;
        this.popupTargetPageUrlRequired = 0;
    }
    /**
     * Q3のフォームバリデーション
     * @param num ラジオボタンの選択結果
     * @param event 入力されたキー情報
     */
    popupTargetPageUrlKeyUp(num: number, event: any) {
        // backspaceキーの入力を検知
        if (event.keyCode === 8 || event.keyCode === 13) {
            for (let i = 0; i <= 5; i++) {
                if (this.popupTargetPageUrlArray.value[i] === '') {
                    this.popupTargetPageUrlRequired = this.popupTargetPageUrlRequired + 1;
                }
            }
            if (this.popupTargetPageUrlRequired === 6 && num === 2) {
                this.publicationSettingsService.popupTargetPageUrlValid = true;
            }
        }
    }
    /**
     * Q3のフォームバリデーション
     * @param num ラジオボタンの選択結果
     * @param event 入力されたキー情報
     */
    popupTargetPageUrlBlur(num: number) {
        this.popupTargetPageUrlRequired = 0;
        this.publicationSettingsService.popupTargetPageUrlValid = false;
        for (let i = 0; i <= 5; i++) {
            if (this.popupTargetPageUrlArray.value[i] === '') {
                this.popupTargetPageUrlRequired = this.popupTargetPageUrlRequired + 1;
            }
        }
        if (this.popupTargetPageUrlRequired === 6 && num === 2) {
            this.publicationSettingsService.popupTargetPageUrlValid = true;
        }
    }

    /**
     * Q3のフォーム追加機能
     */
    addUrlForm() {
        if (this.popupTargetPageUrlArray.length < 12) {
            this.popupTargetPageUrlArray.push(this.fb.control('', Validators.pattern(this.urlRegExp)));
        }
    }

    /**
     * Q3のフォームリセット
     * @param num ラジオボタンの選択結果
     */
    popupTargetPageChange(num: number) {
        this.publicationSettingsService.popupTargetPageUrlValid = false;
        for (let i = 5; i >= 0; i--) {
            this.popupTargetPageUrlArray.removeAt(i);
        }
        for (let i = 1; i <= 6; i++) {
            this.popupTargetPageUrlArray.push(this.fb.control('', Validators.pattern(this.urlRegExp)));
        }
        this.popupTargetPageUrlBlur(num);
    }

    /**
     * Q4のラジオボタンとテキストボックスの値同期
     * @param budgetNum ラジオボタンの値
     */
    adBudgetOptionClick(budgetNum: number) {
        if (budgetNum === 0) {
            this.adBudgetMin = false;
            this.adBudget.value = 500;
        } else if (budgetNum === 1) {
            this.adBudgetMin = false;
            this.adBudget.value = 1000;
        } else if (budgetNum === 2) {
            this.adBudgetMin = false;
            this.adBudget.value = 5000;
        }
    }

    /**
     * Q4のテキストボックスクリック時ラジオボタンの選択を変更
     */
    budgetClick() {
        this.simpleSettingsForm.patchValue({
            adBudgetOption: '4',
        });
    }

    /**
     * Q4のテキストボックス入力時整数かチェック
     */
    budgetChange() {
        this.adBudgetMin = false;
        if (this.adBudget.value < 1) {
            this.adBudget.value = 1;
            this.adBudgetMin = true;
        }
        if (isNaN(this.adBudget.value) === true) {
            this.adBudget.value = 1;
            this.adBudgetMin = true;
        }
        this.adBudget.value = Math.floor(this.adBudget.value);
    }

    /**
     * Q5のラジオボタンの選択によって見せる内容の切り替え
     */
    pictureCreateOptionClick() {
        if (this.pictureCreateOption.value === '1') {
            this.publicationSettingsService.pictureCreateHide = false;
            this.CanvasSettingsComponent.draw();
        } else if (this.pictureCreateOption.value === '2') {
            if (!this.img300) {
                this.publicationSettingsService.pictureUpFlag = true;
            } else {
                this.adInfo.pictureData = this.img300;
                this.adInfo.pictureDataBig = this.imgOrigin;
            }
            this.publicationSettingsService.pictureCreateHide = true;

            setTimeout(() => {
                const uploadButton = document.getElementsByClassName('ui-fileupload-choose')[0];
                const position = uploadButton.getBoundingClientRect();
                this.publicationSettingsService.recommendText.top = position.top + window.scrollY;
                this.publicationSettingsService.recommendText.left = position.left + position.width + window.scrollX;
            }, 100);
        }
    }

    /**
     * アップロードした画像をJimpライブラリで編集し出力
     * @param event アップロードされたファイルの情報
     */
    myUploader(event: any) {
        const file = event.files[0];
        this.reader.readAsDataURL(file);
        this.reader.onload = e => {
            this.pictureSrc = String(e.target['result']);
            jimp.read(this.pictureSrc).then(img => {
                this.resizePath = img.getBase64Async(jimp.MIME_PNG);
                // オリジナル画像を取得
                this.imgOrigin = this.resizePath.__zone_symbol__value;
                // オリジナル画像を送信
                this.publicationSettingsService.uploadOrigin = this.imgOrigin;
                this.adInfo.pictureDataBig = this.imgOrigin;
                // 300pxにリサイズ
                img.resize(300, jimp.AUTO);
                // リサイズした画像データの出力
                this.resizePath = img.getBase64Async(jimp.MIME_PNG);
                this.img300 = this.resizePath.__zone_symbol__value;
                // 300pxを送信
                this.adInfo.pictureData = this.img300;
                this.publicationSettingsService.upload300 = this.img300;
                // 背景サイズの計算
                this.divHeight = img.getHeight() + 24;
                this.publicationSettingsService.pictureUpFlag = false;
            });
        };
    }

    /**
     * アップロードしたバナー画像のクリア
     */
    pictureTrash() {
        this.adInfo.pictureData = null;
        this.adInfo.pictureDataBig = null;
        this.img300 = null;
        this.publicationSettingsService.upload300 = null;
        this.imgOrigin = null;
        this.publicationSettingsService.uploadOrigin = null;
        this.publicationSettingsService.pictureUpFlag = true;
        this.divHeight = 0;
    }
}
